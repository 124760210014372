import Quill, {Parchment} from "quill";
import {Comment, WPSQLComments} from "./WPS_QL_Comments";
import Inline from "quill/blots/inline";
import {co} from "@fullcalendar/core/internal-common";

export class CommentBlot extends Inline {
    static iconClass: string;
    static iconPrefix: string;


    static override create(value) {
        let node = super.create(value);
        CommentBlot.buildComment(value, node);
        return node;
    }

    /*static override value(node) {
        console.log('NODE: ', node);
        console.log('NODE DATA: ', node.getAttribute('data'));
        return JSON.parse(node.getAttribute('data'));
    }*/

    static commentOn(comment: Comment, node) {
        node.classList.add('comment-icon');
        node.classList.add('comment-icon-'+comment.icon);
        node.classList.add('comment-color-'+comment.colorIndex);
        node.classList.remove('comment-off');
    }

    static commentOff(comment: Comment, node) {
        node.classList.remove('comment-icon');
        node.classList.remove('comment-icon-'+comment.icon);
        node.classList.remove('comment-color-'+comment.colorIndex);
        node.classList.add('comment-off');

    }

    static toggleComment(enable: boolean, comment: Comment, node) {
        if (enable) {
            this.commentOn(comment, node);
        } else {
            this.commentOff(comment, node);
        }
    }

    static buildComment(value: string, node) {
        const comment = JSON.parse(value);
        node.setAttribute('comment-data', JSON.stringify(comment));
        node.id = 'RNGCOMMENT-'+comment.uuid;
        node.classList.add('rng-comment');
        node.classList.add('comment-author-'+comment.authorUUID);

        this.commentOn(comment, node);

        node.addEventListener('click', function(e) {
            // e.target should always be node since it is attached to node
            if ( !WPSQLComments.isEnabled ) {
                return;
            }
            const blot: Parchment.Blot = <Parchment.Blot>Quill.find(node);

            const offset = blot.offset(blot.scroll);
            window['angularComponentRef'].zone.run(
                () => {
                    window['angularComponentRef'].showComment(blot, offset);
                }
            );
        });
    }

    static override formats(domNode) {
        return domNode.getAttribute('comment-data') || true;
    }

    override format(name, value) {
        if (name !== this.statics.blotName || !value) {
            super.format(name, value);
        } else {
            CommentBlot.buildComment(value, this.domNode);
        }
    }

    override formats() {
        let formats = super.formats();
        formats['comment'] = CommentBlot.formats(this.domNode);
        return formats;
    }
}

CommentBlot.blotName = 'comment';
CommentBlot.className = 'ql-commentblot';
CommentBlot.tagName = 'mark';

<p-tree *ngIf="isEditMode() && treeModel" class="w-full md:w-30rem" [value]="treeModel" >
    <ng-template let-node pTemplate="section">
        <span (click)="jumpToSection(node.data)" title="Jump to top of {{node.label}}" [innerHtml]="node.icon+' '+node.label"></span><i *ngIf="isEditMode()" class="pi pi-fw pi-pencil" style="vertical-align:super;font-size:.83em" title="Edit metadata for {{node.label}}" (click)="editSectionMetadata(node.data)"></i>
    </ng-template>
</p-tree>
<hr *ngIf="isEditMode() && treeModel" />
<ul *ngIf="model.length > 0" class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
    <!-- li>
        <a href="https://www.primefaces.org/primeblocks-ng/#/">
            <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'banner-primeblocks' : 'banner-primeblocks-dark'}}.png" alt="Prime Blocks" class="w-full mt-3"/>
        </a>
    </li -->
</ul>


import {Component, Input, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DataViewModule} from "primeng/dataview";
import {MenuModule} from "primeng/menu";
import {OrderListModule} from "primeng/orderlist";
import {SharedModule} from "primeng/api";
import {SkeletonModule} from "primeng/skeleton";
import {SplitButtonModule} from "primeng/splitbutton";
import {TooltipModule} from "primeng/tooltip";
import {
    DataService
} from "../../../service/data.service";
import {ProjectDescriptorUse} from 'src/app/api/enums';
import {DividerModule} from "primeng/divider";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {FormsModule} from "@angular/forms";
import {ProjectDescriptorPanelComponent} from "../../project-descriptor/project-descriptor-panel.component";
import {InputSwitchModule} from "primeng/inputswitch";
import {Project} from "../../../service/db/entities/Project";

@Component({
  selector: 'project-profile',
  standalone: true,
    imports: [
        ButtonModule,
        ConfirmDialogModule,
        DataViewModule,
        MenuModule,
        OrderListModule,
        SharedModule,
        SkeletonModule,
        SplitButtonModule,
        TooltipModule,
        DividerModule,
        InputTextModule,
        InputTextareaModule,
        FormsModule,
        ProjectDescriptorPanelComponent,
        InputSwitchModule
    ],
  templateUrl: './project-profile.component.html'
})

export class ProjectProfileComponent implements OnInit {
    protected title: string | null;
    protected summary: string | null;

    private _project: Project;

    namespace: string;

    @Input() set project(value: Project) {
        this._project = value;
        this.namespace = value.uuid;
        this.initializeValue();
    }

    get project(): Project {
        return this._project;
    }

    constructor( private dataService: DataService) {

    }

    ngOnInit() {
        this.initializeValue();
    }

    initializeValue() {

        /*if (this.project.projectProfileSelections) {
            let values: Map<number, string> = new Map<number, string>();
            this.project.projectProfileSelections.items.forEach(pds => {
                values.set(pds.projectDescriptorId, pds.value);
            })
            this.projectDescriptorService.setPDCurrentValues(this.namespace, values);
        }*/
    }

    save() {
        //console.log(this.values);
        this.dataService.saveProjectTempToProfile(this.namespace, this.project);
    }

    protected readonly ProjectDescriptorUse = ProjectDescriptorUse;
}


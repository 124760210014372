import { EmbedBlot, Root } from 'parchment';
import {ProjectSchema, ProjectSchemas} from "../../api/project_schemas";
import {Project} from "../../service/db/entities/Project";

export class MetadataBlot extends EmbedBlot {

    static override className = 'ql-tocblot';
    static override blotName = 'toc';
    static override tagName = 'span';

    public static readonly BLOT_ID = 'RNGTOC';

    public toc: DocumentMetadata;

    constructor(scroll: Root, domNode) {
        super(scroll, domNode);

        this.toc = MetadataBlot.value(domNode);
    }

    static buildMetadata(value: string, node) {
        const toc = JSON.parse(value);

        node.setAttribute('metadata', JSON.stringify(toc));
        node.id = MetadataBlot.BLOT_ID;
        node.classList.add('rng-metadata');
        node.classList.add('no-reveal-codes');
        //node.innerText = value;
    }

    static override create(value) {
        let node = super.create(value);

        MetadataBlot.buildMetadata(JSON.stringify(value), node);
        return node;
    }

    static override value(node) {
        let metadataStr = node.getAttribute('metadata');
        if (! metadataStr) {
            metadataStr = node.getAttribute('toc-data');
        }
        return JSON.parse(metadataStr);
    }

}

export class DocumentMetadata {
    projectSchema: ProjectSchema;
    tocVersion: number;
    schemaVersion: number;
    documentUpdater: string;
    documentVersion: number;
    documentTimestamp: Date;

    static CURRENT_VERSION: number = 1.2;
    static createInitialMetadata(project: Project): DocumentMetadata {
        return {
            documentVersion: 0,
            documentTimestamp: new Date(),
            documentUpdater: project.writerProfile.platformUser.uuid,
            projectSchema: ProjectSchemas.DEFAULT_PROJECT_SCHEMA,
            schemaVersion: ProjectSchemas.CURRENT_VERSION,
            tocVersion: DocumentMetadata.CURRENT_VERSION //Upgrade to 1.2 complete
        };
    }
}

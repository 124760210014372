import {Component, NgZone, OnDestroy, OnInit, signal} from '@angular/core';
import {PlatformUser, Project} from "../../../API";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ProjectProfileComponent} from "../writer-dashboard/project-profile/project-profile.component";
import {
    WriterResourceMatchesComponent
} from "../writer-dashboard/writer-resource-matches/writer-resource-matches.component";
import {ButtonModule} from "primeng/button";
import {DataService, RoleProject} from "../../service/data.service";
import {DialogModule} from "primeng/dialog";
import {MessageService} from "primeng/api";
import {BadgeModule} from "primeng/badge";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PaginatorModule} from "primeng/paginator";
import {v4 as uuid} from "uuid";
import Quill from "quill";
import {Comment, Reply, WPSQLComments} from "../comment/WPS_QL_Comments";
import {QuillEditorComponent} from "ngx-quill";
import {InputSwitchModule} from "primeng/inputswitch";
import {CommentComponent} from "../comment/comment.component";
import {PlatformRole} from "../../api/enums";
import {RNGQLAutosave} from "./RNG_QL_Autosave";
import {ErrorService} from "../../service/error.service";

@Component({
    selector: 'editor',
    standalone: true,
    templateUrl: './editor.component.html',
    imports: [
        NgIf,
        ProjectProfileComponent,
        WriterResourceMatchesComponent,
        ButtonModule,
        DialogModule,
        BadgeModule,
        NgClass,
        NgForOf,
        PaginatorModule,
        QuillEditorComponent,
        InputSwitchModule,
        CommentComponent
    ],
    styleUrl: './editor.component.scss'
})
export class EditorComponent implements OnInit, OnDestroy {

    static platformUser: PlatformUser;
    private static quill: any;
    private referenceToAutosaveModule: RNGQLAutosave;
    private referenceToCommentModule: WPSQLComments;
    protected isReadOnly: boolean;
    get platformUser() { return EditorComponent.platformUser; }

    protected uuid: string;

    protected newReply: Reply;


    private roToolbar = {
        container: [
            ['force-save'],
            ['comments-toggle', 'comments-add']
        ],
        handlers: {
            'comments-toggle': () => {
                console.log('toggle was clicked')
            },
            'comments-add': () => {
                console.log('add was clicked')
            },
            'force-save': () => {
                console.log('force save was clicked')
            }
        }
    };

    private rwToolbar = {
        container: [
            ['force-save'],
            ['bold', 'italic', 'underline', 'strike'],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
            [{'align': []}],
            [{'font': []}, {size: ['small', false, 'large', 'huge']}],
            ['comments-toggle', 'comments-add'],
            ['clean']
        ],
        handlers: {
            'comments-toggle': () => {
                console.log('toggle was clicked')
            },
            'comments-add': () => {
                console.log('add was clicked')
            },
            'force-save': () => {
                console.log('force save was clicked')
            }
        }
    };

    protected modules: any = {
        toolbar: this.roToolbar,
        comments: {
            enabled: true,
            commentAddClick: this.commentAddClick, // get called when `ADD COMMENT` btn on options bar is clicked
            commentToggleUpdate: this.commentsToggle, // get called when you click `COMMENTS` btn on options bar for you to do additional things beside color on/off. Color on/off is already done before the callback is called.
            editorComponent: this
        },
        autosave: {
            onForceSaveClickCallback: this.onForceSaveClickCallback,
            onDocumentDirtyCallback: this.onDocumentDirtyCallback,
            updateFullExternalDocument: this.updateFullExternalDocument,
            onDocumentSaved: this.onDocumentSaved,
            editorComponent: this,
            timeout: 5000
        }
    };

    static userColors: {background: string, foreground: string }[] = [
        {
            background: '#ffcccc',
            foreground: '#ff0000'
        },
        {
            background: '#ccffcc',
            foreground: '#00ff00'
        },
        {
            background: '#ccccff',
            foreground: '#0000ff'
        },
        {
            background: '#ffffcc',
            foreground: '#ffff00'
        },
        {
            background: '#ccffff',
            foreground: '#00ffff'
        },
        {
            background: '#ffccff',
            foreground: '#ff00ff'
        },
        {
            background: '#ffe0cc',
            foreground: '#ff6600'
        },
        {
            background: '#e0ffe0',
            foreground: '#66ff66'
        },
        {
            background: '#e0ccff',
            foreground: '#6600ff'
        },
        {
            background: '#ffcce0',
            foreground: '#ff0066'
        },
        {
            background: '#e0cce0',
            foreground: '#660066'
        },
    ];
    get userColors() { return EditorComponent.userColors; }

    private subscriptions: Subscription[] = [];

    //Need to know what role I have on this specific project
    protected showComments = signal(false);
    protected showMultiple: boolean = false;

    protected static showAddCommentDialog = signal(false);
    get showAddCommentDialog() { return EditorComponent.showAddCommentDialog; }
    //set showAddCommentDialog(sacd: boolean) { EditorComponent.showAddCommentDialog = sacd; }

    protected static selectedComment = signal<Comment>(null);
    get selectedComment() { return EditorComponent.selectedComment; }

    private static roleProject: RoleProject;
    get roleProject() { return EditorComponent.roleProject; }

    private static EDITOR_COMPONENT: EditorComponent;

    constructor(private route: ActivatedRoute,
                private errorService: ErrorService,
                private dataService: DataService,
                private zone: NgZone) {

        EditorComponent.EDITOR_COMPONENT = this;

        window['angularComponentRef'] = {
            zone: this.zone,
            showComment: (comment, offset) => { this.displayComment(comment, offset)},
            component: this
        }

        const icons = Quill.import('ui/icons');

        if (!Quill.import('modules/comments')) {
            Quill.register('modules/comments', WPSQLComments);
            icons['comments-toggle'] = '<i id="commentsToggleButton" class="pi pi-eye" aria-hidden="true"></i>';
            icons['comments-add'] = '<i id="commentsAdButton" class="pi pi-comment" aria-hidden="true"></i>';
        }
        if (!Quill.import('modules/autosave')) {
            Quill.register('modules/autosave', RNGQLAutosave);
            icons['force-save'] = '<i id="forceSaveButton" class="pi pi-save" aria-hidden="true"></i>';
        }

        this.subscriptions.push(this.dataService.user.subscribe(u => {
            EditorComponent.platformUser = u;
        }));
        this.subscriptions.push(this.route.params.subscribe(async params => {
            //console.log(params);
            this.uuid = params['project'];
            //Need this to return to the previous url
            //console.log('DEBUG: Initial: ', params['retUrl']);
            //console.log('DEBUG: decodeURI: ', decodeURI(params['retUrl']));
            //console.log('DEBUG: b64 decode: ', atob(decodeURI(params['retUrl'])));
            this.dataService.redirectUrl = atob(decodeURI(params['retUrl']));

            const prj: Project = this.dataService.getProjectForUUID(this.uuid);
            this.dataService.setSelectedProject(prj);
            EditorComponent.roleProject = this.dataService.getRoleBasedProjectForUUID(this.uuid);
            if (!prj.currentDeltaContent) {
                this.dataService.switchToBaseLayout();
            }
        }));
    }

    onEditorCreated(quill) {
        EditorComponent.quill = quill;
        EditorComponent.quill.setContents(JSON.parse(this.dataService.coreProject.currentDeltaContent));
        EditorComponent.quill.on(Quill.events.TEXT_CHANGE, this.update.bind(this));
    }

    update() { //Called when the content of the editor is changed by a user
        this.referenceToAutosaveModule.flagDocumentDirty();
        /* const length = this.calculate();
        let label = this.options.unit;
        if (length !== 1) {
            label += 's';
        }
        this.container.innerText = `${length} ${label}`; */
        //console.log('Update Called on Comment Plugin');
        //console.log(JSON.stringify(WPSQLComments.quill.getContents()));
    }

    commentAddClick(callback, wpsQlCommentsOb) {
        //console.log("AddComment Triggered");
        //NOTE: "this" is not set when Quill calls this function!
        //console.log("WPSQLComments: ", wpsQlCommentsOb);
        let colorIdx = EditorComponent.roleProject.colorIndex;

        let comment: Comment = {
            colorIndex: colorIdx,
            icon: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'beta-reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'author' :
                        'error' //No Role Project
            ),
            authorRole: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'Beta Reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'Author' :
                        'error' //No Role Project
            ),
            comment: null,
            authorUUID: EditorComponent.platformUser.uuid,
            authorScreenName: EditorComponent.platformUser.screenName,
            timestamp: new Date(),
            hidden: false,
            replies: null,
            uuid: uuid(),
            range: wpsQlCommentsOb.quill.getSelection(),
            callback: callback
        };
        EditorComponent.selectedComment.set(comment);
        EditorComponent.showAddCommentDialog.set(true);
    }

    commentsToggle(enabled: boolean) {
        // comments btn callback
        console.log("CommentsToggle Triggered");

        const el = document.getElementById("commentsToggleButton");
        el.className="pi "+(enabled?"pi-eye":"pi-eye-slash");
        const css = document.getElementById("ql-comment-visible");
    }

    displayComment(comment, offset) {
        const commentData = comment.domNode.attributes['comment-data'].value;
        let commentOb = <Comment>JSON.parse(commentData);
        EditorComponent.selectedComment.set(commentOb);
        //console.log('Multi: ', this.showMultiple);
        let colorIdx = EditorComponent.roleProject.colorIndex;
        this.newReply = {
            colorIndex: colorIdx,
            icon: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'beta-reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'author' :
                        'error' //No Role Project
            ),
            authorRole: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'Beta Reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'Author' :
                        'error' //No Role Project
            ),
            comment: null,
            authorUUID: EditorComponent.platformUser.uuid,
            authorScreenName: EditorComponent.platformUser.screenName,
            timestamp: new Date(),
            hidden: false,
            replies: null
        };
        this.showComments.set(true);
    }

    cancelAdd() {
        EditorComponent.showAddCommentDialog.set(false);
        this.showComments.set(false);

    }

    saveNewComment() {
        EditorComponent.showAddCommentDialog.set(false);
        EditorComponent.selectedComment().callback(EditorComponent.selectedComment());
    }

    onForceSaveClickCallback(autoSaveOb) {
        //this.icons['force-save'] = '<i class="text-green-900 pi pi-save" aria-hidden="true"></i>';
    }

    onDocumentDirtyCallback(autoSaveOb) {
        const el = document.getElementById("forceSaveButton");
        el.style.color = "orange";
    }

    async updateFullExternalDocument(delta: any, autoSaveOb) {
        await EditorComponent.EDITOR_COMPONENT.dataService.updateDeltaContent(delta);
    }

    onDocumentSaved(autoSaveOb) {
        const el = document.getElementById("forceSaveButton");
        el.style.color = "green";
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    async ngOnInit() {
        switch (EditorComponent.roleProject.role) {
            case PlatformRole.WRITER:
                this.isReadOnly = false;
                this.modules.toolbar = this.rwToolbar;
                break;
            case PlatformRole.BETAREADER:
                this.isReadOnly = true;
                this.modules.toolbar = this.roToolbar;
                break;
            default:
                this.isReadOnly = true;
                this.modules.toolbar = this.roToolbar;
                this.errorService.handleError({
                    ancillaries: [EditorComponent.roleProject.role],
                    detail: "Unable to match Role to select ReadOnly: "+EditorComponent.roleProject.role,
                    severity: "SEVERE",
                    stack: undefined,
                    summary: "Unable to match Role to select ReadOnly"
                });
        }
    }

    saveNewReply() {
        this.showComments.set(false);
        console.log("REPLIES 1", EditorComponent.selectedComment().replies);
        if (!EditorComponent.selectedComment().replies) {
            EditorComponent.selectedComment().replies = [];
        }
        console.log("REPLIES 2", EditorComponent.selectedComment().replies);
        EditorComponent.selectedComment().replies.push(this.newReply);
        console.log("REPLIES 3", EditorComponent.selectedComment().replies);
        //console.log('RESULT: ', EditorComponent.selectedComment());
        this.referenceToCommentModule.updateCommentReplies(EditorComponent.selectedComment());
    }

    setReferenceToAutosaveModule(reference: RNGQLAutosave) {
        this.referenceToAutosaveModule = reference;
    }

    setReferenceToCommentModule(reference: WPSQLComments) {
        this.referenceToCommentModule = reference;
    }
}

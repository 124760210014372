<p-dialog header="Select Document to Upload" [(visible)]="displayUploader" [modal]="true" showEffect="fade" class="xl:col-6 col-9" (onHide)="displayUploader = false">
    <p-fileUpload #fileUpload name="uploadFile" [multiple]="false" accept=".txt,.doc,.docx,.pdf,.odt,.html,.htm,.etherpad,.rtf" maxFileSize="10000000" (onUpload)="onTemplatedUpload()" (onSelect)="onSelectedFiles($event)" >
        <ng-template pTemplate="file"></ng-template>
        <ng-template pTemplate="header"><span></span>
            <!-- div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                <p-progressBar [value]="totalSizePercent" [showValue]="false" styleClass="md:w-20rem h-1rem w-full md:ml-auto" [ngClass]="{ 'exceeded-progress-bar': totalSizePercent > 100 }">
                    <span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
                </p-progressBar>
            </div -->
        </ng-template>
        <ng-template pTemplate="content" let-files let-uploadedFiles="uploadedFiles" let-removeFileCallback="removeFileCallback" let-removeUploadedFileCallback="removeUploadedFileCallback">
            <div *ngIf="files?.length > 0">
                <h5>Pending</h5>
            </div>
            <div *ngIf="files?.length > 0">
                <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                    <div *ngFor="let file of files; let i = index" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                        <!-- div>
                            <img role="presentation" [alt]="file.name" [src]="file.objectURL" width="100" height="50" />
                        </div -->
                        <span class="font-semibold">{{ file.name }}</span>
                        <div>{{ formatSize(file.size) }}</div>
                        <p-badge value="Pending" severity="warning" />
                        <p-button icon="pi pi-times" (click)="onRemoveTemplatingFile($event, file, removeFileCallback, i)" [outlined]="true" [rounded]="true" severity="danger" />
                    </div>
                </div>
            </div>
            <div *ngIf="uploadedFiles?.length > 0">
                <h5>Completed</h5>
            </div>
            <div *ngIf="uploadedFiles?.length > 0">
                <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                    <div *ngFor="let file of uploadedFiles; let i = index" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                        <div>
                            <img role="presentation" [alt]="file.name" [src]="file.objectURL" width="100" height="50" />
                        </div>
                        <span class="font-semibold">{{ file.name }}</span>
                        <div>{{ formatSize(file.size) }}</div>
                        <p-badge value="Completed" class="mt-3" severity="success" />
                        <p-button icon="pi pi-times" (onClick)="removeUploadedFileCallback(i)" [outlined]="true" [rounded]="true" severity="danger" />
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="empty" let-chooseCallback="chooseCallback">
            <div class="flex align-items-center justify-content-center flex-column">
                <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
                <p class="mt-4 mb-0">Drag and drop files or click <a href (click)="chooseFile()">here</a> to upload.</p>
            </div>
        </ng-template>
    </p-fileUpload>
    <ng-template pTemplate="footer" let-uploadCallback="uploadCallback" let-clearCallback="clearCallback">
        <button pButton (click)="onTemplatedUpload()" icon="pi pi-upload" class="p-button-outlined" label="Ok" [disabled]="!files || files.length === 0" ></button>
        <button pButton icon="pi pi-times" (click)="onClearTemplatingUpload()" label="Cancel" class="p-button-outlined p-button-warning"></button>
    </ng-template>
</p-dialog>
<div class="card" *ngIf="!dataService.coreProject.currentDeltaContent">
    <div class="col-6">
        <p-button label="Import Document" (click)="displayUploader=true" icon="pi pi-upload" />
    </div>
    <div class="col-6">
        <p-button (click)="editDocument()" label="Start New Document" icon="pi pi-pen-to-square" />
    </div>
</div>

<div class="card" *ngIf="dataService.coreProject.currentDeltaContent">
    <!-- div class="col-6">
        <p-button label="Import Document" (click)="displayUploader=true" icon="pi pi-upload" />
    </div -->
    <div class="col-6">
        <p-splitButton
            label="Edit Project"
            (click)="editDocument()"
            [model]="editItems"
            icon="pi pi-pen-to-square"/>
    </div>
</div>

<span *ngIf="projectMatch?.project && projectDisplayMode == ProjectDescriptorUse.BETA_READER_VIEW_OF_PROJECT" >
    <div class="flex flex-column md:flex-row align-items-center p-3 w-full">
        <img height="64px" [src]="'assets/book_image_placeholder.png'" [alt]="projectMatch.project.title" class="my-4 md:my-0 shadow-2 mr-5"/>
        <div class="flex-1 flex flex-column align-items-center text-center md:text-left">
            <div class="font-bold text-2xl">{{projectMatch.project.title}}</div>
            <div class="mb-2">{{projectMatch.project.summary}}</div>
            <p-rating [readonly]="true" [cancel]="false" styleClass="mb-2"></p-rating>
            <div class="flex align-items-center mt-2">
                <span *ngFor="let category of projectMatch.categories | slice:0:3">
                    <span *ngIf="Utils.isArray(category.value)">
                        <span *ngFor="let tag of category.value | slice:0:1">
                            <i [class]="'pi mr-2 '+tag.icon"></i>
                            <span class="font-semibold">{{tag.value}}</span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
            <span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end">{{projectMatch.author}}</span>
            <span *ngIf="(!projectMatch.betaReaderProject.writerStatus || projectMatch.betaReaderProject.writerStatus === WriterStatus.ACTIVE.valueOf())">
                <p-button *ngIf="!showDetail" [raised]="true" icon="pi pi-info-circle" label="See More" (click)="showDetail=true" styleClass="mb-2 p-button-sm"></p-button>
                <p-button *ngIf="showDetail" icon="pi pi-info-circle" label="Close Detail" (click)="showDetail=false" styleClass="mb-2 p-button-sm"></p-button>
            </span>
            <span *ngIf="!projectMatch.betaReaderProject.writerStatus" [class]="'project-badge status-' + projectMatch.betaReaderProject.betaReaderStatus.toLowerCase()">{{projectMatch.betaReaderProject.betaReaderStatus}}</span>
            <span *ngIf="projectMatch.betaReaderProject.writerStatus" [class]="'project-badge status-' + projectMatch.betaReaderProject.writerStatus.toLowerCase()">{{projectMatch.betaReaderProject.writerStatus}}</span>
        </div>
    </div>
    <hr/>
    <project-detail [projectMatch]="projectMatch" *ngIf="showDetail"></project-detail>
</span>
<span *ngIf="projectDisplayMode == ProjectDescriptorUse.WRITER_PROJECT" >
    <h1>ERROR - Please Report: Please use Project-Profile-Component for Writer View of Project</h1>
</span>

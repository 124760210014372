import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import {initialLoadingGuard, LoadingPageComponent} from "./components/loading-page/loading-page.component";
import {WriterDashboardComponent} from "./components/writer-dashboard/writer-dashboard.component";
import {BetaReaderDashboardComponent} from "./components/beta-reader-dashboard/beta-reader-dashboard.component";
import {
    InitialRoleSelectionPageComponent
} from "./components/initial-role-selection-page/initial-role-selection-page.component";
import {
    cognitoAuthGuard,
} from "./service/data.service";
import {EditorComponent} from "./components/editor/editor.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', canActivate: [initialLoadingGuard], component: AppLayoutComponent,
                children: [
                    { path: '', canActivate: [cognitoAuthGuard], component: LoadingPageComponent },
                    { path: 'admin',  canActivate: [cognitoAuthGuard], loadChildren: () => import('./components/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule) },
                    { path: 'writer/:project',  canActivate: [cognitoAuthGuard], component: WriterDashboardComponent },
                    { path: 'beta_reader/:project',  canActivate: [cognitoAuthGuard], component: BetaReaderDashboardComponent },
                    { path: 'writer',  canActivate: [cognitoAuthGuard], component: WriterDashboardComponent },
                    { path: 'beta_reader',  canActivate: [cognitoAuthGuard], component: BetaReaderDashboardComponent },
                    { path: 'startup',  canActivate: [cognitoAuthGuard], component: InitialRoleSelectionPageComponent },
                    { path: 'uikit', loadChildren: () => import('./components/uikit/uikit.module').then(m => m.UIkitModule) },
                    { path: 'utilities',  canActivate: [cognitoAuthGuard], loadChildren: () => import('./components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    { path: 'blocks',  canActivate: [cognitoAuthGuard], loadChildren: () => import('./components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    { path: 'pages',  canActivate: [cognitoAuthGuard], loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule) },
                    { path: 'content/:project/:retUrl',  canActivate: [cognitoAuthGuard], component: EditorComponent }
                ]
            },

            { path: 'auth', canActivate: [initialLoadingGuard], loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule)},
            { path: 'landing', loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule) },
            { path: 'notfound', component: NotfoundComponent },
            { path: 'loading', loadChildren: () => import('./components/loading-page/loading-page.module').then(m => m.LoadingPageModule) },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

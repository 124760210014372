/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c0bc07e7-e8da-441e-9019-c0f0519bf25f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QFf8BhGIx",
    "aws_user_pools_web_client_id": "3b2lsf9cua61v9ko8lfr75c6v2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "com-runeandgear-profile-storage163957-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://pxqyshcqqzgibmmffxmed3mbg4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ddlsnkw3xbdefkgcuh4vuulqii",
    "aws_content_delivery_bucket": "platform.runeandgear.com-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://db5p6jvl0z1vi.cloudfront.net"
};


export default awsmobile;

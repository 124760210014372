<div class="surface-card">
    <div class="font-medium text-3xl text-900 mb-3">Beta Reader Information</div>
    <div class="w-12 md:w-12 flex justify-content-end">
        <button *ngIf="!resourceMatch.project.writerStatus" pButton pRipple label="Accept Beta Reader" (click)="setAccept()" icon="pi pi-check" class="p-button-text"></button>
        <button *ngIf="!resourceMatch.project.writerStatus" pButton pRipple label="I'm Not Interested" (click)="setNotInterested()" icon="pi pi-times" class="p-button-text align-right"></button>
    </div>
    <!-- div class="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div -->
    <ul class="list-none p-0 m-0">
        <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Beta Reader</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{resourceMatch.name}}</div>
        </li>
        <!-- li *ngFor="let category of resourceMatch.categories" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">{{category.enUsLabel}}</div>
            <div *ngIf="isArray(category.value)" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <span *ngFor="let tag of category.value"><p-chip  [icon]="category.icon?'pi '+category.icon:''" [label]="tag" class="mr-2"></p-chip></span>
            </div>
            <div *ngIf="!isArray(category.value)" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {{category.value}}
            </div>
            <div class="w-6 md:w-2 flex justify-content-end">
            </div>
        </li -->
        <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">About this Beta Reader</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                {{resourceMatch.bioSketch}}</div>
            <div class="w-6 md:w-2 flex justify-content-end">
                <!-- button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-text"></button -->
            </div>
        </li>
    </ul>
</div>

import {Component, OnDestroy} from '@angular/core';
import {ChartModule} from "primeng/chart";
import {CurrencyPipe, NgIf, NgStyle} from "@angular/common";
import {MenuModule} from "primeng/menu";
import {TableModule} from "primeng/table";
import {Subscription} from "rxjs";
import {LayoutService} from "../../layout/service/app.layout.service";
import {ProjectMatchType, DataService} from "../../service/data.service";
import {PlatformRole, ProjectDescriptorUse} from 'src/app/api/enums';
import {BetaReaderReadingProfileComponent} from "./beta-reader-reading-profile/beta-reader-reading-profile.component";
import {BetaReaderProfile, BetaReaderProject, PlatformUser, Project} from "../../../API";
import {ActivatedRoute} from "@angular/router";
import {BetaReaderProjectMatchesComponent} from "./beta-reader-project-matches/beta-reader-project-matches.component";
import {ProjectDisplayComponent} from "../project-display/project-display.component";
import {ProjectCountComponent} from "../stat-blocks/project-count/project-count.component";
import {ProjectWordCountComponent} from "../stat-blocks/project-word-count/project-word-count.component";
import {UserCountComponent} from "../stat-blocks/user-count/user-count.component";
import {UserWordCountComponent} from "../stat-blocks/user-word-count/user-word-count.component";
import {PlaceholderComponent} from "../stat-blocks/placeholder/placeholder.component";
import {Button} from "primeng/button";

@Component({
    templateUrl: './beta-reader-dashboard.component.html',
    standalone: true,
    imports: [
        NgStyle,
        BetaReaderReadingProfileComponent,
        TableModule,
        MenuModule,
        ChartModule,
        CurrencyPipe,
        NgIf,
        BetaReaderProjectMatchesComponent,
        ProjectDisplayComponent,
        ProjectCountComponent,
        ProjectWordCountComponent,
        UserCountComponent,
        UserWordCountComponent,
        PlaceholderComponent,
        Button
    ]
})
export class BetaReaderDashboardComponent implements OnDestroy {
    protected platformUser: PlatformUser;
    isAllowed: boolean = false;
    protected betaReader: BetaReaderProfile;
    protected selectedBRProject: BetaReaderProject;
    protected selectedProjectMatch: ProjectMatchType;
    protected uuid: string;

    constructor(protected dataService: DataService,
                private route: ActivatedRoute,
                public layoutService: LayoutService) {
        this.subscriptions.push(this.dataService.userObs.subscribe(u => {
            this.platformUser = u;
            this.isAllowed = u.roles.includes(PlatformRole.BETAREADER.valueOf());
        }));
        this.subscriptions.push(this.dataService.betaReader.subscribe(u => {
            console.log('I HAVE READER', u);
            this.betaReader = u;
            this.subscriptions.push(this.route.params.subscribe(async params => {
                console.log(params);
                this.uuid = params['project'];

                if (this.uuid !== "new") {
                    this.selectedBRProject = this.betaReader.projects.items.find(p => p.uuid === this.uuid);

                    const prj: Project = this.dataService.getProjectForUUID(this.selectedBRProject.uuid);
                    this.dataService.setSelectedProject(this.selectedBRProject);

                    this.selectedProjectMatch = {
                        author: prj.usesPenName?prj.penNameGivenName+' '+prj.penNameSurname:'',
                        betaReaderProject: this.selectedBRProject,
                        categories: null,
                        project: prj,
                        score: 0,
                        title: prj.title?prj.title:''
                    };

                    //console.log("selectedBRProject: ", this.selectedBRProject, "selectedProjectMatch: ", this.selectedProjectMatch, "UUID: ", this.uuid);
                } else {
                    this.selectedBRProject = undefined;
                    this.selectedProjectMatch = undefined;
                    this.dataService.setSelectedProject(undefined);
                }
            }));
        }));
    }

    private subscriptions: Subscription[] = [];
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    protected readonly ProjectDescriptorUse = ProjectDescriptorUse;
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from "../../service/data.service";
import {Router} from "@angular/router";
import {PlatformRole} from "../../api/enums";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ChartModule} from "primeng/chart";
import {MenuModule} from "primeng/menu";
import {TableModule} from "primeng/table";
import {StyleClassModule} from "primeng/styleclass";
import {PanelMenuModule} from "primeng/panelmenu";
import {ButtonModule} from "primeng/button";
import {ProjectDescriptorPanelComponent} from "../project-descriptor/project-descriptor-panel.component";
import {AvatarModule} from "primeng/avatar";
import {AvatarGroupModule} from "primeng/avatargroup";
import {ToastModule} from "primeng/toast";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {PlatformUser} from "../../../API";
import {DividerModule} from "primeng/divider";
import {Subscription} from "rxjs";

@Component({
    templateUrl: './initial-role-selection-page.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ChartModule,
        MenuModule,
        TableModule,
        StyleClassModule,
        PanelMenuModule,
        ButtonModule,
        ProjectDescriptorPanelComponent,
        AvatarModule,
        AvatarGroupModule,
        ToastModule,
        BlockUIModule,
        ProgressSpinnerModule,
        DividerModule
    ],
})
export class InitialRoleSelectionPageComponent implements OnInit, OnDestroy {
    private platformUser: PlatformUser;
    processing: boolean = false;

    constructor(private dataService: DataService) {
        this.subscriptions.push(dataService.user.subscribe(u => {
            this.platformUser = u;
            console.log('Startup loading', this.platformUser);
            if (this.platformUser.roles.length !== 0) {
                console.log('Clearing and redirecting from selection screen');
                this.dataService.redirectUrl = null;
                this.dataService.redirectToHome();
            }
        }));
    }
    async ngOnInit() {
    }

    private subscriptions: Subscription[] = [];
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
    async addRoles(newRoles: PlatformRole[]) {
        console.log("Adding Role");
        this.processing = true;
        if (newRoles.includes(PlatformRole.WRITER)) {
            await this.dataService.addWriterRole();
        }
        if (newRoles.includes(PlatformRole.BETAREADER)) {
            await this.dataService.addBetaReaderRole();
        }
        console.log("Disabling processing");
        this.processing = false;
        this.dataService.redirectToHome(true);
    }

    protected readonly PlatformRole = PlatformRole;
}

<div class="grid">
    <project-count class="col-12 lg:col-6 xl:col-3"></project-count>
    <user-count class="col-12 lg:col-6 xl:col-3"></user-count>
    <project-word-count *ngIf="selectedProject" class="col-12 lg:col-6 xl:col-3"></project-word-count>
    <placeholder *ngIf="!selectedProject" class="col-12 lg:col-6 xl:col-3"></placeholder>
    <user-word-count class="col-12 lg:col-6 xl:col-3"></user-word-count>
    <div class="col-12 w-full" *ngIf="selectedProject">
        <div class="xl:col-6 col-12">
            <editor-space [user]="platformUser" [project]="selectedProject"></editor-space>
        </div>
        <div class="xl:col-6 col-12">
            <writer-resource-matches [project]="selectedProject"></writer-resource-matches>
        </div>
        <div class="xl:col-6 col-12">
            <project-profile [project]="selectedProject"></project-profile>
        </div>
    </div>
</div>


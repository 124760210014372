<p-confirmDialog header="Confirmation" key="confirmDelete" icon="pi pi-exclamation-triangle"
                 [style]="{width: '350px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-toast></p-toast>
<p-blockUI [blocked]="progressService.blocked | async">
    <p-progressSpinner aria-label="Loading"></p-progressSpinner><br/>
    <div style="font-size: 3rem">{{progressService.progressMessage | async}}</div>
</p-blockUI>
<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    <app-config></app-config>
    <div class="layout-mask"></div>
</div>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ChipModule} from "primeng/chip";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {DataView, DataViewModule} from "primeng/dataview";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {RatingModule} from "primeng/rating";
import {
    DataService,
    WriterResourceMatchType
} from "../../../service/data.service";
import {Project} from "../../../../API";
import {NgForOf, NgIf, SlicePipe} from "@angular/common";
import {DialogModule} from "primeng/dialog";
import {BetaReaderStatus} from "../../../api/enums";
import {ResourceDetailComponent} from "../../resource-detail/resource-detail.component";
import {ProjectDetailComponent} from "../../project-display/project-detail/project-detail.component";

@Component({
  selector: 'writer-resource-matches',
  standalone: true,
    imports: [
        ChipModule,
        ButtonModule,
        RippleModule,
        DataViewModule,
        DropdownModule,
        InputTextModule,
        RatingModule,
        ProjectDetailComponent,
        NgForOf,
        NgIf,
        DialogModule,
        SlicePipe,
        ResourceDetailComponent
    ],
  templateUrl: './writer-resource-matches.component.html',
  styleUrl: './writer-resource-matches.component.scss'
})
export class WriterResourceMatchesComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];
    private _project: Project;

    @Input() set project(value: Project) {
        this._project = value;
        this.dataService.startProjectResourceMatches(this.project.id);
    }

    get project(): Project {
        return this._project;
    }
    ngOnDestroy(): void {
        this.dataService.stopMatches();
        this.subscriptions.forEach(s => s.unsubscribe());
    }


    writerResourceMatches: Record<string, WriterResourceMatchType> = {};
    matchMeta: Map<string, {showDetail: boolean, tags: {label: string, icon: string}[]}> = new Map<string, {showDetail: boolean; tags: {label: string; icon: string}[]}>();
    sortOrder: number = 0;
    sortField: string = '';

    writerResourceMatchesArray: WriterResourceMatchType[] = [];


    constructor(private dataService: DataService) { }

    async ngOnInit() {
        console.log('Project: ',this.project);
        this.subscriptions.push(this.dataService.projectResourceMatches.subscribe(m => {
            this.writerResourceMatches = m;
            console.log('Matches: ', m);
            this.writerResourceMatchesArray = [];
            for (const p of Object.values(m)) {
                this.writerResourceMatchesArray.push(p);
                if (!this.matchMeta.has(p.project.uuid)) {
                    //Set defaults
                    let tags: {label: string, icon: string}[] = [];
                    tags.push({label: 'Taggy', icon: 'pi-book'});

                    this.matchMeta.set(p.project.uuid, {
                        showDetail: false,
                        tags: tags
                    });
                }
            }
        }));
    }

    onSortChange(event: any) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onFilter(dv: DataView, event: Event) {
        dv.filter((event.target as HTMLInputElement).value);
    }

    protected readonly Array = Array;

    isArray(value: any) {
        return value instanceof Array;
    }

    protected readonly BetaReaderStatus = BetaReaderStatus;
    protected readonly Object = Object;
}

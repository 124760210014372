import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ProductService } from './service/product.service';
import { CountryService } from './service/country.service';
import { CustomerService } from './service/customer.service';
import { EventService } from './service/event.service';
import { IconService } from './service/icon.service';
import { NodeService } from './service/node.service';
import { PhotoService } from './service/photo.service';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import {ConfirmationService, MessageService} from "primeng/api";
import {Amplify} from "aws-amplify";
import awsconfig from '../../aws-exports.js';
import {QuillModule} from "ngx-quill";
//import {EditorLayoutModule} from "./layout/editor.layout.module";
//import {fetchAuthSession} from "aws-amplify/auth";

Amplify.configure(awsconfig);

@NgModule({
    declarations: [
        AppComponent, NotfoundComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        AmplifyAuthenticatorModule,
        QuillModule.forRoot()
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, ConfirmationService, MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

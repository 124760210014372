import {Component, Input} from '@angular/core';
import {ChipModule} from "primeng/chip";
import {NgForOf, NgIf} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {MessageService} from "primeng/api";
import {ProjectMatchType, DataService} from "../../../service/data.service";
import { BetaReaderStatus } from 'src/app/api/enums';
import {Utils} from "../../../api/utils";

@Component({
  selector: 'project-detail',
  standalone: true,
    imports: [
        ChipModule,
        NgForOf,
        NgIf,
        ButtonModule,
        RippleModule
    ],
  templateUrl: './project-detail.component.html',
  styleUrl: './project-detail.component.scss'
})
export class ProjectDetailComponent {
    @Input() projectMatch!: ProjectMatchType;

    protected readonly Array = Array;

    constructor(private messageService: MessageService, private dataService: DataService) {
    }

    async setInterested() {
        console.log('BetaReaderProject Save: ',this.projectMatch.betaReaderProject);
        if (!this.projectMatch.betaReaderProject.id) {
            await this.dataService.saveBetaReaderProject(this.projectMatch.betaReaderProject);
        }
        await this.dataService.setBetaReaderStatus(this.projectMatch.betaReaderProject, BetaReaderStatus.PENDING_RESPONSE);
        this.projectMatch.betaReaderProject.betaReaderStatus = BetaReaderStatus.PENDING_RESPONSE;
        this.messageService.add({ severity: 'info', summary: 'Writer Notified', detail: 'We\'ve notified the writer of your interest in being a beta reader for this project.' });
    }

    async setNotInterested() {
        console.log('BetaReaderProject Save: ',this.projectMatch.betaReaderProject);
        if (!this.projectMatch.betaReaderProject.id) {
            await this.dataService.saveBetaReaderProject(this.projectMatch.betaReaderProject);
        }
        await this.dataService.setBetaReaderStatus(this.projectMatch.betaReaderProject, BetaReaderStatus.NOT_INTERESTED);
        this.projectMatch.betaReaderProject.betaReaderStatus = BetaReaderStatus.NOT_INTERESTED;
    }

    protected readonly BetaReaderStatus = BetaReaderStatus;
    protected readonly Utils = Utils;
}

<div class="grid" *ngIf="!isAllowed">
    <div class="surface-ground flex align-items-center justify-content-center overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%);">
                <div class="surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
                    <div class="grid flex flex-column align-items-center">
                        <div class="flex justify-content-center align-items-center bg-orange-500 border-circle" style="width:3.2rem; height:3.2rem;">
                            <i class="text-50 pi pi-fw pi-lock text-2xl"></i>
                        </div>
                        <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2">Access Denied</h1>
                        <span class="text-600 mb-5">You do not have the necessary permisions. Please contact admins.</span>
                        <img src="../../../../assets/demo/images/access/asset-access.svg" alt="Access denied" class="mb-5" width="80%">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="grid">
    <project-count class="col-12 lg:col-6 xl:col-3"></project-count>
    <user-count class="col-12 lg:col-6 xl:col-3"></user-count>
    <project-word-count *ngIf="uuid && uuid!='new'" class="col-12 lg:col-6 xl:col-3"></project-word-count>
    <placeholder icolor="cyan" *ngIf="!uuid || uuid=='new'" class="col-12 lg:col-6 xl:col-3"></placeholder>
    <placeholder icolor="purple" class="col-12 lg:col-6 xl:col-3"></placeholder>
    <div *ngIf="uuid && uuid=='new' && !selectedBRProject && betaReader.projectDescriptorSelections && betaReader.projectDescriptorSelections?.length > 0" class="col-12 xl:col-6">
        <div class="card">
            <h5>Available Project Matches</h5>
            <beta-reader-project-matches [betaReader]="betaReader"></beta-reader-project-matches>
        </div>
    </div>
    <div *ngIf="uuid && uuid!='new' && selectedProjectMatch" class="col-12 xl:col-6">
        <div class="card" *ngIf="dataService.getSelectedProject()">
            <!-- div class="col-6">
                <p-button label="Import Document" (click)="displayUploader=true" icon="pi pi-upload" />
            </div -->
            <div class="col-6">
                <p-button (click)="dataService.switchToEditLayout()" label="Review Project" icon="pi pi-pen-to-square" />
            </div>
        </div>
        <div class="card">
            <project-display [projectMatch]="selectedProjectMatch" [projectDisplayMode]="ProjectDescriptorUse.BETA_READER_VIEW_OF_PROJECT"></project-display>
        </div>
    </div>
    <div *ngIf="uuid && uuid=='new' && !selectedBRProject" [class]="!betaReader.projectDescriptorSelections || betaReader.projectDescriptorSelections.length === 0 ? 'xl:col-offset-3':''" class="col-12 xl:col-6">
        <beta-reader-reading-profile></beta-reader-reading-profile>
    </div>
</div>


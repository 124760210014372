<p-confirmDialog header="Confirmation" key="confirmDelete" icon="pi pi-exclamation-triangle"
                 [style]="{width: '350px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<div class="card">
    <div class="flex justify-content-between align-items-center ">
        <div class="grid col-12 pt-0 mb-2">
            <div class="col pb-0">
                <h5>{{!betaReader.projectDescriptorSelections || betaReader.projectDescriptorSelections.items.length === 0 ? 'Create' : 'Edit'}} Your Reading Profile</h5>
            </div>
            <div class="col-fixed pr-0 pt-0 relative top-0 left-0">
                <img src="../../../../assets/badges/beta_reader.png" class="absolute right-0 top-0"/>
            </div>
        </div>
    </div>
    <div>
        <div class="card grid grid-nogutter pt-0 mt-0">
            <project-descriptor-panel [namespace]="namespace" [use]="ProjectDescriptorUse.BETA_READER_PROFILE"></project-descriptor-panel>
            <p-button class="col-4 col-offset-4 pt-3" [label]="!betaReader.projectDescriptorSelections || betaReader.projectDescriptorSelections.items.length === 0 ? 'Create Profile and Search' : 'Save Profile and Search'" icon="pi pi-search" (onClick)="save()" ></p-button>
        </div>
    </div>
</div>

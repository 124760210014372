import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ChipModule} from "primeng/chip";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {DataView, DataViewModule} from "primeng/dataview";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {RatingModule} from "primeng/rating";
import {NgForOf, NgIf, SlicePipe} from "@angular/common";
import {DialogModule} from "primeng/dialog";
import {ProjectDescriptorUse, WriterStatus} from 'src/app/api/enums';
import {ProjectDetailComponent} from "./project-detail/project-detail.component";
import {ProjectMatchType, DataService} from "../../service/data.service";
import {Utils} from "../../api/utils";
import {ProjectDescriptorPanelComponent} from "../project-descriptor/project-descriptor-panel.component";

@Component({
  selector: 'project-display',
  standalone: true,
    imports: [
        ChipModule,
        ButtonModule,
        RippleModule,
        DataViewModule,
        DropdownModule,
        InputTextModule,
        RatingModule,
        ProjectDetailComponent,
        NgForOf,
        NgIf,
        DialogModule,
        SlicePipe,
        ProjectDescriptorPanelComponent
    ],
  templateUrl: './project-display.component.html',
  styleUrl: './project-display.component.scss'
})
export class ProjectDisplayComponent implements OnInit {

    @Input() projectMatch!: ProjectMatchType;

    showDetail: boolean = false;

    @Input() projectDisplayMode!: ProjectDescriptorUse;

    constructor(private dataService: DataService) { }

    ngOnInit(): void {
        console.log("DISPLAY projectMatch: ", this.projectMatch);
    }

    protected readonly WriterStatus = WriterStatus;
    protected readonly Utils = Utils;
    protected readonly ProjectDescriptorUse = ProjectDescriptorUse;
}

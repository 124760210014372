import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ProjectProfileComponent} from "../writer-dashboard/project-profile/project-profile.component";
import {
    WriterResourceMatchesComponent
} from "../writer-dashboard/writer-resource-matches/writer-resource-matches.component";
import {ButtonModule} from "primeng/button";
import {DataService} from "../../service/data.service";
import {DialogModule} from "primeng/dialog";
import {FileUpload, FileUploadModule} from "primeng/fileupload";
import {MenuItem, MessageService} from "primeng/api";
import {BadgeModule} from "primeng/badge";
import {ProgressService} from "../../service/progress.service";
import mammoth from "mammoth";
import {HtmlToDelta} from "quill-delta-from-html/html_to_delta";
import {SplitButtonModule} from "primeng/splitbutton";
import {Project} from "../../service/db/entities/Project";
import {PlatformUser} from "../../service/db/entities/PlatformUser";
import {DocumentService} from "../../service/document.service";


@Component({
    selector: 'editor-space',
    standalone: true,
    templateUrl: './editor-space.component.html',
    imports: [
        NgIf,
        ButtonModule,
        DialogModule,
        FileUploadModule,
        BadgeModule,
        NgForOf,
        SplitButtonModule
    ],
    styleUrl: './editor-space.component.scss'
})
export class EditorSpaceComponent implements OnInit{
    @Input() project!: Project;
    @Input() user!: PlatformUser;

    @ViewChild('fileUpload') fileUpload: FileUpload;

    editItems: MenuItem[] = [
        {label: 'Re-Import Document', icon: 'pi pi-upload', command: (x) => {
                this.displayUploader=true
            }}
    ];

    constructor(private progressService: ProgressService, protected documentService: DocumentService, protected dataService: DataService) {}

    async ngOnInit() {
        //const result = await this.etherpadService.createAuthor('MEEWOM');
        //console.log('CommentComponent: ', result);
    }

    files = [];

    totalSize: number = 0;

    totalSizePercent: number = 0;


    chooseFile(): boolean {
        this.fileUpload.choose();
        return false;
    }



    onRemoveTemplatingFile(event, file, removeFileCallback, index) {
        removeFileCallback(event, index);
        this.totalSize -= parseInt(this.formatSize(file.size));
        this.totalSizePercent = this.totalSize / 10;
    }

    onClearTemplatingUpload() {
        this.fileUpload.clear();
        this.totalSize = 0;
        this.totalSizePercent = 0;
        this.displayUploader = false;
    }

    private cleanUpHTML(inHTML: string): string {
        return inHTML
            .replaceAll('<p></p>','<br/>')
            .replaceAll('</p>','</p><br/>');
    }

    async onTemplatedUpload() {
        this.progressService.blockWithMessage('Converting and Importing File');

        //console.log("FILES: ",this.files[0]);
        const result = await mammoth.convertToHtml({arrayBuffer: await this.files[0].arrayBuffer()}, {
            //Options
                includeDefaultStyleMap: true,
                includeEmbeddedStyleMap: true,
                ignoreEmptyParagraphs: false
        });
        let content = this.cleanUpHTML(result.value);
        console.log(content);
        const delta = new HtmlToDelta().convert(content);
        await this.documentService.updateDeltaContent(delta);
        this.dataService.switchToEditLayout();
        this.progressService.unBlock();
        //console.log('DELTA RESULT: ', delta);

            //this.dataService.switchToEditLayout();
    }

    onSelectedFiles(event) {
        this.files = event.currentFiles;
        this.files.forEach((file) => {
            this.totalSize += parseInt(this.formatSize(file.size));
        });
        this.totalSizePercent = this.totalSize / 10;
    }

    uploadEvent(callback) {
        callback();
    }

    formatSize(bytes) {
        const k = 1024;
        const dm = 3;
        const sizes = ['KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) {
            return `0 ${sizes[0]}`;
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

        return `${formattedSize} ${sizes[i]}`;
    }

    protected readonly Object = Object;
    protected displayUploader: boolean = false;

    async editDocument() {
        this.dataService.switchToEditLayout();
    }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ChipModule} from "primeng/chip";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {DataView, DataViewModule} from "primeng/dataview";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {RatingModule} from "primeng/rating";
import {SelectItem} from "primeng/api";
import {ProjectMatchType, DataService} from "../../../service/data.service";
import {NgForOf, NgIf, SlicePipe} from "@angular/common";
import {DialogModule} from "primeng/dialog";
import {ProjectDescriptorUse, WriterStatus} from "../../../api/enums";
import {ProjectDetailComponent} from "../../project-display/project-detail/project-detail.component";
import {ProjectDisplayComponent} from "../../project-display/project-display.component";
import {BetaReaderProfile} from "../../../service/db/entities/BetaReaderProfile";
import {BetaReaderProject} from "../../../service/db/entities/BetaReaderProject";
import {Project} from "../../../service/db/entities/Project";

@Component({
    selector: 'beta-reader-project-matches',
    standalone: true,
    imports: [
        ChipModule,
        ButtonModule,
        RippleModule,
        DataViewModule,
        DropdownModule,
        InputTextModule,
        RatingModule,
        NgForOf,
        NgIf,
        DialogModule,
        ProjectDisplayComponent
    ],
    templateUrl: './beta-reader-project-matches.component.html',
    styleUrl: './beta-reader-project-matches.component.scss'
})
export class BetaReaderProjectMatchesComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    @Input() betaReader!: BetaReaderProfile;

    ngOnDestroy(): void {
        this.dataService.stopMatches();
        this.subscriptions.forEach(s => s.unsubscribe());
    }


    projectMatches: Record<string, ProjectMatchType> = {};
    matchMeta: Record<string, { showDetail: boolean, tags: { label: string, icon: string }[] }> = {};
    sortOptions: SelectItem[] = [];
    sortOrder: number = 0;
    sortField: string = '';
    projectMatchesArray: { betaReaderProject: BetaReaderProject; project: Project }[] = [];

    constructor(private dataService: DataService) {
    }

    async ngOnInit() {
        console.log('BetaReader: ', this.betaReader);
        this.projectMatches = await this.dataService.getMatches(this.betaReader.id);
        this.projectMatchesArray = [...Object.values(this.projectMatches)];
        console.log('Matches: ', this.projectMatches);
        for (const p of Object.values(this.projectMatches)) {
            if (!this.matchMeta[p.betaReaderProject.uuid]) {
                //Set defaults
                let tags: { label: string, icon: string }[] = [];
                tags.push({label: 'Taggy', icon: 'pi-book'});
                this.matchMeta[p.betaReaderProject.uuid] = {
                    showDetail: false,
                    tags: tags
                };
            }
        }
    }

    onSortChange(event: any) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onFilter(dv: DataView, event: Event) {
        dv.filter((event.target as HTMLInputElement).value);
    }

    protected readonly Array = Array;
    protected readonly WriterStatus = WriterStatus;
    protected readonly ProjectDescriptorUse = ProjectDescriptorUse;
}

import {inject, Injectable, OnInit, signal, WritableSignal} from '@angular/core';

import {BehaviorSubject, firstValueFrom, Observable, Subscription} from "rxjs";
import {Project} from "./db/entities/Project";


import {ErrorService, LogProvider} from "./error.service";
import {GatherDebugData} from "./gather-debug-data";

import {
    DocumentMetadata,
    MetadataBlot
} from "../components/contents-management/MetadataBlot";
import {Delta} from "quill/core";

import {EditorComponent} from "../components/editor/editor.component";
import {StorageMap} from "@ngx-pwa/local-storage";
import {ORM} from "../utils";
import {ProjectSchemas} from "../api/project_schemas";
import {Section, SectionBlot} from "../components/contents-management/SectionBlot";
import {Content} from "./db/entities/Content";
import {DataSource} from "typeorm";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class DocumentService implements GatherDebugData {

    private readonly LOG = LogProvider.getLogger('DocumentService');

    private hydration = undefined;

    selectedProject: Project = null; // Filled on editorCreate
    private _selectedProjectObs: BehaviorSubject<Project> = new BehaviorSubject(this.selectedProject);
    public readonly selectedProjectObs: Observable<Project> = this._selectedProjectObs.asObservable();

    contentId: number = null; // Filled on editorCreate
    // private _contentObs: BehaviorSubject<Content> = new BehaviorSubject(this.content);
    // public readonly contentObs: Observable<Content> = this._contentObs.asObservable();

    static documentMetadata: DocumentMetadata = null; // Filled on editorCreate
    private _documentMetadataObs: BehaviorSubject<DocumentMetadata> = new BehaviorSubject(DocumentService.documentMetadata);
    public readonly documentMetadataObs: Observable<DocumentMetadata> = this._documentMetadataObs.asObservable();

    static tableOfContents: TableOfContents = null; // Filled on editorCreate
    private _tableOfContentsObs: BehaviorSubject<{tableOfContents: TableOfContents, documentMetadata: DocumentMetadata}> = new BehaviorSubject({
        tableOfContents: DocumentService.tableOfContents,
        documentMetadata: DocumentService.documentMetadata
    });
    public readonly tableOfContentsObs: Observable<{tableOfContents: TableOfContents, documentMetadata: DocumentMetadata}> = this._tableOfContentsObs.asObservable();

    //TODO: We'll need a push mechanism of some sort for multi-user updates - WebSocket or something.
    //TODO:   Also needs mechanism to lock the document to use offline?

    contentloaded: boolean = false;

    constructor(
        private storage: StorageMap,
        private router: Router,
        private errorService: ErrorService) {

        errorService.registerServiceForDebug('DocumentService', this);

    }

    async updateDeltaContent(delta: any) {
        try {
            await (await ORM.getORMDatasource()).getRepository(Content).update({projectId: this.selectedProject.id},
                {
                    delta: delta
                });
        } catch (e) {
            this.errorService.handleError({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to update project content. See log for details',
                error: e,
                stack: e.stack
            });
        }
    }

    public async getDocumentMetadata(content: Delta): Promise<DocumentMetadata> {
        this.LOG.debug("getDocumentMetadata currentDelta: ", content);
        console.log(content);
        const shouldBeDM: DocumentMetadata = await this.checkDocumentMetadata(this.selectedProject, content.ops[0].insert[MetadataBlot.blotName]);
        this.LOG.debug("getDocumentMetadata shouldBeDM: ", shouldBeDM);

        return shouldBeDM;
    }

    public async getTOC(content: Delta): Promise<TableOfContents> {
        this.LOG.debug("getTOC currentDelta: ", content);
        console.log(content);
        const shouldBeTOC: TableOfContents = await this.buildTOC(content.ops[0].insert[MetadataBlot.blotName]);
        this.LOG.debug("getTOC shouldBeTOC: ", shouldBeTOC);

        return shouldBeTOC;
    }

    private async buildTOC(documentMetadata: DocumentMetadata): Promise<TableOfContents> {
        if (!EditorComponent.readyToInitializeQuill()) {
            return null;
        }

        let elements = document.getElementsByClassName(SectionBlot.className);

        if (elements.length === 0) {
            if (!EditorComponent.quill) {
                return null; //Not ready yet. . .
            }
            const topLevel = Section.createInitialSection(this.selectedProject);
            this.LOG.debug('Inserting Embed',topLevel, documentMetadata, this.selectedProject);

            EditorComponent.quill.insertEmbed(1,'section', JSON.stringify(topLevel), 'api');

            elements = document.getElementsByClassName(SectionBlot.className);
        }

        let baseToc: TableOfContents = {
            topLevelSectionLink: {
                childLinks: [],
                section: SectionBlot.value(elements.item(0)),
                parentLink: null
            },
            sectionLinkDictionary: {}
        }

        let parentSectionLink: SectionLink = baseToc.topLevelSectionLink;

        baseToc.sectionLinkDictionary[baseToc.topLevelSectionLink.section.uuid] = baseToc.topLevelSectionLink;

        for (let i = 1; i < elements.length; i++) {
            let section: Section = SectionBlot.value(elements[i]);
            console.log("SECTION: ", section);

            //First, walk up the tree until you find an eligible parent.
            while (parentSectionLink.section.level >= section.level) {
                parentSectionLink = parentSectionLink.parentLink;
            }
            //Add it to that parent and reset parent
            const newSectionLink = {
                childLinks: [],
                section: section,
                parentLink: parentSectionLink
            };
            parentSectionLink.childLinks.push(newSectionLink);
            baseToc.sectionLinkDictionary[section.uuid] = newSectionLink;
            console.log("BASETOC: ", baseToc);
            parentSectionLink = newSectionLink;
        }
        DocumentService.tableOfContents = baseToc;
        this._tableOfContentsObs.next({
            tableOfContents: baseToc,
            documentMetadata: documentMetadata
        });
        return baseToc;
    }

    async checkDocumentMetadata(proj: Project, documentMetadata: DocumentMetadata): Promise<DocumentMetadata> {

        this.LOG.debug("documentMetadata in checkDocumentMetadata: ", documentMetadata);

        //Let's make sure the level on the toc is legal for what is IN the toc. . .

        //let needsUpdate: boolean = false;

        if (!documentMetadata) {
            //No TOC - time to add one
            documentMetadata = DocumentMetadata.createInitialMetadata(proj);
            EditorComponent.quill.insertEmbed(0,'toc', JSON.stringify(documentMetadata), 'api');
            this.LOG.debug('Setting documentMetadata: ', documentMetadata);
        }

        // if (documentMetadata.projectSchema.projectLevel < documentMetadata.topLevelSectionLink.section.level) {
        //     //Uh-oh - fix it - this may have happened from an old bug, but the data still needs fixed!
        //     documentMetadata.projectSchema.projectLevel = documentMetadata.topLevelSectionLink.section.level;
        //     //needsUpdate = true;
        // }

        //VERSIONING CODE
        this.LOG.debug('Toc Version: ', documentMetadata.tocVersion);
        if (!documentMetadata.tocVersion) {
            documentMetadata.tocVersion = 0.1;
            this.LOG.debug('No Toc Version - set: ', documentMetadata);
        }

        //CURRENT VERSION
        const VERSION = 1.1;
        if (VERSION !== documentMetadata.tocVersion) {
            //Data upgrade needed - hardcode each step here.
            if (documentMetadata.tocVersion === 0.1) {
                //projectSchema missing
                documentMetadata.projectSchema = ProjectSchemas.DEFAULT_PROJECT_SCHEMA; //This will always use the hardcoded "default", since this means that this predated setting it on the project itself
                documentMetadata.schemaVersion = ProjectSchemas.CURRENT_VERSION;
                documentMetadata.tocVersion = 1.0; //Upgrade to 1.0 complete
            }
            if (documentMetadata.tocVersion === 1.0) {
                //Install the initial section blot - now done in buildTOC
                documentMetadata.tocVersion = 1.1; //Upgrade to 1.1 complete
                //this.updateDeltaContent(EditorComponent.quill.getContents());
                //needsUpdate = true;
            }

            //Upgrades complete - save it back to the mode
            this.LOG.info('Upgraded Document Metadata: ', documentMetadata);
            await this.saveDocumentMetadata(documentMetadata);
        }

        //documentMetadata = await this.buildTOC(documentMetadata);
        //
        // if (needsUpdate) {
        //     await this.saveTableOfContents(proj, toc);
        //     return toc;
        // }

        return documentMetadata;
    }

    async jumpToSection(section: Section) {
        await EditorComponent.jumpToSection(section);
    }

    getDebugInfo() {
        return {

            // writerProjectDescriptorCategories: this.writerProjectDescriptorCategories,
            // betaReaderViewOfProjectDescriptorCategories: this.betaReaderViewOfProjectDescriptorCategories
        };
    }

    async saveDocumentMetadata(documentMetadata: DocumentMetadata) {
        this.LOG.debug("Saving Document Metadata: ",documentMetadata);
        try {
            //TOC is ONLY going to be in the document from now (TOC v1.2) on. Don't store it in storage or the db.

            //Update the document embed to have the new TOC - then save the project
            let delta = EditorComponent.quill.getContents();
            delta.ops[0].insert.toc = documentMetadata;
            //this.LOG.debug("Saving TOC - delta: ",delta);

            EditorComponent.quill.setContents(delta,'api');
            EditorComponent.EDITOR_COMPONENT.update();

            DocumentService.documentMetadata = documentMetadata;
            this._documentMetadataObs.next(documentMetadata);
            //this.selectedProject = project;
            //this._selectedProjectObs.next(project);
        } catch (e) {
            this.errorService.handleError({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to update Document Metadata. See log for details',
                error: e,
                stack: e.stack
            });
            return;
        }
    }

    async setSelectedProjectAndLoadContent(selectedProject: Project): Promise<Delta> {
        const content = await (await ORM.getORMDatasource()).getRepository(Content).findOneBy({projectId: selectedProject.id});
        this.contentId = content.id;
        (await this.setSelectedProject(selectedProject));
        let documentMetadata: DocumentMetadata = await this.getDocumentMetadata(content.delta);
        DocumentService.documentMetadata = documentMetadata;
        this._documentMetadataObs.next(documentMetadata);

        let toc: TableOfContents = await this.getTOC(content.delta);
        DocumentService.tableOfContents = toc;
        this._tableOfContentsObs.next({
            tableOfContents: toc,
            documentMetadata: documentMetadata
        });
        this.contentloaded = true;
        return content.delta;
    }

    async setSelectedProject(selectedProject: Project) {
        this.LOG.debug("Set Selected Project: ", selectedProject);
        this.selectedProject = selectedProject;
        this._selectedProjectObs.next(selectedProject);
    }

    //This returns the SectionLink that contains this child - or null if none
    getParentSectionLinkFromTOC(child: Section, level: number = null): {
        sectionLink: SectionLink,
        childIndex: number
    } {
        const toc = DocumentService.tableOfContents.topLevelSectionLink;
        if (child.uuid === toc.section.uuid) {
            return null; //No parent - this is the top
        }
        return this.getParentSectionLink(child, toc, level);
    }

    private getParentSectionLink(child: Section, possibleParent: SectionLink, level: number): {
        sectionLink: SectionLink,
        childIndex: number
    } {

        const idx = possibleParent.childLinks.findIndex(sl => sl.section.uuid === child.uuid);
        if (idx > -1) {
            if (!level || possibleParent.section.level === level) {
                return {sectionLink: possibleParent, childIndex: idx}; //Found it, so this is the parent group; (And it is the right level)
            } else {
                return this.getParentSectionLinkFromTOC(possibleParent.section, level); //Use this found parent as the new child and see if it has the right level as parent
            }
        }
        for (let link of possibleParent.childLinks) {
            const sl = this.getParentSectionLink(child, link, level);
            if (sl) {
                //Found it - let it bubble up
                return sl;
            }
        }
        return null; //Not on this branch
    }

    getSectionLinkFromTOC(child: Section): { sectionLink: SectionLink, childIndex: number } {
        const toc = DocumentService.tableOfContents.topLevelSectionLink;
        this.LOG.debug("Child and toc", typeof child, child, toc, child.uuid, toc.section.uuid, (child.uuid === toc.section.uuid));

        if (child.uuid === toc.section.uuid) {
            return {sectionLink: toc, childIndex: 0}; //No parent - this is the top - return it
        }
        return this.getSectionLink(child, toc);
    }

    private getSectionLink(child: Section, possibleParent: SectionLink): {
        sectionLink: SectionLink,
        childIndex: number
    } {

        const idx = possibleParent.childLinks.findIndex(sl => sl.section.uuid === child.uuid);
        if (idx > -1) {
            return {sectionLink: possibleParent.childLinks[idx], childIndex: idx}; //Found it, so this is the parent group - return the child;
        }
        for (let link of possibleParent.childLinks) {
            const sl = this.getSectionLink(child, link);
            if (sl) {
                //Found it - let it bubble up
                return sl;
            }
        }
        return null; //Not on this branch
    }

   /* async getProjectForUUID(uuid: string): Promise<Project> {
        const projectRepository = (await ORM.getORMDatasource()).getRepository(Project);
        return projectRepository.findOneBy({
            uuid: uuid
        });
    }

    async setContent(content: Delta) {
        this.LOG.debug("Saving Content: ", content);
        //const contentStr = JSON.stringify(content);
        try {
            await (await ORM.getORMDatasource()).getRepository(Content).update(this.contentId, {delta: content});
        } catch (e) {
            this.errorService.handleError({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to update Contents. See log for details',
                error: e,
                stack: e.stack
            });
            return;
        }
    }*/ //This is now handled by the editor - and it automatically saves after each change


    static getSectionString(section: Section): string {
        const toc = DocumentService.tableOfContents;
        const dm = DocumentService.documentMetadata;
        //console.log('CHECKING SECTION');
        //console.log('DM: ',dm);
        //console.log('TOC: ',toc);
        //console.log('SECTION: ',section);
        if (toc && dm && section) {
            //console.log('SECTION STRING');
            let idx: number = 1;
            if ( toc.sectionLinkDictionary[section.uuid]?.parentLink ) {
                idx = toc.sectionLinkDictionary[section.uuid].parentLink.childLinks.findIndex(sl => sl.section.uuid === section.uuid) + 1;
            }
            //console.log('IDX: ',idx);
            //console.log('LEVEL: ', section.level);
            //console.log('LEVEL NAME: ', dm.projectSchema.sectionLayout[section.level].sectionName);

            return dm.projectSchema.sectionLayout[section.level].sectionName + ' ' + idx;
        }
        return "Loading . . . ";
    }
}

export class TableOfContents {
    topLevelSectionLink: SectionLink | null;
    sectionLinkDictionary: any;
}

export class SectionLink {
    section: Section;
    childLinks: SectionLink[];
    parentLink: SectionLink | null;
}

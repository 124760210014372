<div class="layout-topbar">
    <a class="layout-topbar-logo" href="https://www.runeandgear.com">
        <img style="width:64px;height:64px" src="assets/randg_logo_{{layoutService.config.colorScheme === 'light' ? 'white' : 'black'}}.png" alt="logo">
        <span><img style="height:64px" src="../../assets/randg_scrivendium.png"/></span>
    </a>
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>


    <button pTooltip="Switch to Project Mode" *ngIf="documentService.selectedProject && isEditMode()" style="padding: 0" class="p-link layout-topbar-menu-button layout-topbar-button" (click)="dataService.switchToBaseLayout()">
        <i class="pi pi-objects-column"></i>
        <span>Edit Mode</span>
    </button>
    <button pTooltip="Switch to Editor Mode" *ngIf="documentService.selectedProject && !isEditMode()" style="padding: 0" class="p-link layout-topbar-menu-button layout-topbar-button" (click)="dataService.switchToEditLayout()">
        <i class="pi pi-pen-to-square"></i>
        <span>Project Mode</span>
    </button>

    <p-menu #profileMenu [model]="profileMenuItems" [popup]="true"></p-menu>
    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="profileMenu.toggle($event)">
        <i class="pi pi-user"></i>
        <span>Profile</span>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button pTooltip="Switch to Project Mode" *ngIf="documentService.selectedProject && isEditMode()" class="p-link layout-topbar-button" (click)="dataService.switchToBaseLayout();">
            <i class="pi pi-objects-column"></i>
            <span>Edit Mode</span>
        </button>
        <button pTooltip="Switch to Editor Mode" *ngIf="documentService.selectedProject && !isEditMode()" class="p-link layout-topbar-button" (click)="dataService.switchToEditLayout();">
            <i class="pi pi-pen-to-square"></i>
            <span>Project Mode</span>
        </button>
        <button class="p-link layout-topbar-button" (click)="profileMenu.toggle($event)">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
    </div>
</div>

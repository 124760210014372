import {Component, ElementRef, OnInit, signal, ViewChild} from '@angular/core';
import {MenuItem, MenuItemCommandEvent} from 'primeng/api';
import {LayoutService} from "./service/app.layout.service";
import {DataService} from "../service/data.service";
import {ProgressService} from "../service/progress.service";
import {ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot} from "@angular/router";
import {DocumentService} from "../service/document.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    profileMenuItems!: MenuItem[];

    protected isEditMode = signal(false);

    constructor(public route: Router, public layoutService: LayoutService, protected dataService: DataService, protected documentService: DocumentService, protected progressService: ProgressService) {
        this.route.events.subscribe(e => {
            //console.log('Event:', e);
            if (e instanceof NavigationEnd) {
                console.log('Updating editMode signal:', this.route.url.toLowerCase());
                this.isEditMode.set(this.route.url.toLowerCase().startsWith('/content'));
            }
        });
    }
    async ngOnInit() {

        this.profileMenuItems = [
            {label: 'Welcome, '+this.dataService.getScreenName(), icon: 'pi pi-fw pi-user'},
            {separator: true},
            {label: 'Profile (Coming Soon)', icon: 'pi pi-fw pi-user'},
            {label: 'Report an Issue',
                icon: 'pi pi-fw pi-exclamation-circle',
                url: 'https://docs.google.com/forms/d/e/1FAIpQLSd3jTm_TAIx_j9v1m-EoQDavuBUN-UFWeKUCC2VyTlXWv7FuA/viewform?usp=pp_url&entry.960898102=RGS761542-872165',
                target: '_blank'},
            {separator: true},
            {label: 'Sign Out', icon: 'pi pi-fw pi-sign-out', command: e => this.dataService.signOut()},
        ];
    }


}

import {Component, OnDestroy} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DataViewModule} from "primeng/dataview";
import {MenuModule} from "primeng/menu";
import {OrderListModule} from "primeng/orderlist";
import {SharedModule} from "primeng/api";
import {SkeletonModule} from "primeng/skeleton";
import {SplitButtonModule} from "primeng/splitbutton";
import {TooltipModule} from "primeng/tooltip";
import {
    DataService
} from "../../../service/data.service";
import {PlatformRole, ProjectDescriptorUse} from 'src/app/api/enums';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {DividerModule} from "primeng/divider";
import {ProjectDescriptorPanelComponent} from "../../project-descriptor/project-descriptor-panel.component";
import {PlatformUser} from "../../../service/db/entities/PlatformUser";
import {BetaReaderProfile} from "../../../service/db/entities/BetaReaderProfile";

@Component({
  selector: 'beta-reader-reading-profile',
  standalone: true,
    imports: [
        ButtonModule,
        ConfirmDialogModule,
        DataViewModule,
        MenuModule,
        OrderListModule,
        SharedModule,
        SkeletonModule,
        SplitButtonModule,
        TooltipModule,
        DividerModule,
        ProjectDescriptorPanelComponent
    ],
  templateUrl: './beta-reader-reading-profile.component.html',
  styleUrl: './beta-reader-reading-profile.component.scss'
})

export class BetaReaderReadingProfileComponent implements OnDestroy {
    protected platformUser: PlatformUser;
    protected betaReader: BetaReaderProfile;
    protected namespace: string;

    constructor(private router: Router, private dataService: DataService) {
        //We need them both, so we've got to wait for both and update when either changes
        this.subscriptions.push(this.dataService.betaReader.subscribe(u => {
            this.betaReader = u;
            this.namespace = 'BRP_'+this.betaReader.id;
            console.log("Namespace: ", this.namespace, "projectDescriptorSelections: ", this.betaReader.projectDescriptorSelections);
            if (this.betaReader.projectDescriptorSelections) {
                this.dataService.fillOriginalValues(this.namespace, this.betaReader.projectDescriptorSelections);
            } else {
                console.log("No betareader profile");
            }
        }));
        this.subscriptions.push(this.dataService.userObs.subscribe(data => {
            this.platformUser = data;

            if (!this.platformUser.roles.includes(PlatformRole.BETAREADER.valueOf())) {
                console.log('NOT AUTHORIZED TO VIEW PAGE>>>>>BETA READER');
                this.dataService.redirectToHome();
            }
        }));
    }

    private subscriptions: Subscription[] = [];
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    save() {
        //console.log(this.values);
        this.dataService.saveBetaReaderTempToProfile(this.betaReader, this.namespace);
    }

    protected readonly ProjectDescriptorUse = ProjectDescriptorUse;
}

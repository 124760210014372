    <div *ngIf="projectMatchesArray && projectMatchesArray.length === 0">
        <strong>Unfortunately, there aren't any projects that match your reading profile - Please adjust your reading profile, or check back soon!</strong>
    </div>
    <div *ngIf="projectMatchesArray && projectMatchesArray.length > 0">
        <p-dataView #dv [value]="projectMatchesArray" [paginator]="true" [rows]="9" filterBy="project.title" [sortField]="sortField" [sortOrder]="sortOrder">
                <ng-template pTemplate="header">
                    <div class="flex flex-column md:flex-row md:justify-content-between gap-2">
                        <p-dropdown [options]="sortOptions" placeholder="Best Match" (onChange)="onSortChange($event)"></p-dropdown>
                        <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input type="search" pInputText placeholder="Search by Title" (input)="onFilter(dv, $event)">
                            </span>
                    </div>
                </ng-template>

                <ng-template let-pm pTemplate="list">
                    <div class="col-12" *ngFor="let projectMatch of pm">
                        <project-display [projectMatch]="projectMatch" [projectDisplayMode]="ProjectDescriptorUse.BETA_READER_VIEW_OF_PROJECT"></project-display>
                    </div>
                </ng-template>
            </p-dataView>
    </div>

import { Component } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {ChartModule} from "primeng/chart";
import {CommonModule} from "@angular/common";
import {MenuModule} from "primeng/menu";
import {TableModule} from "primeng/table";
import {Subscription} from "rxjs";
import {LayoutService} from "../../layout/service/app.layout.service";
import {FormsModule} from "@angular/forms";
import {StyleClassModule} from "primeng/styleclass";
import {PanelMenuModule} from "primeng/panelmenu";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {ProjectDescriptorComponent} from "../project-descriptor/project-descriptor-entries/project-descriptor/project-descriptor.component";
import {DataService} from "../../service/data.service";
import {InputTextareaModule} from "primeng/inputtextarea";
import {PlatformUser, Project, WriterProfile} from "../../../API";
import {PlatformRole} from "../../api/enums";
import {
    BetaReaderReadingProfileComponent
} from "../beta-reader-dashboard/beta-reader-reading-profile/beta-reader-reading-profile.component";
import {ProjectProfileComponent} from "./project-profile/project-profile.component";
import {ActivatedRoute} from "@angular/router";
import {WriterResourceMatchesComponent} from "./writer-resource-matches/writer-resource-matches.component";
import {ErrorService} from "../../service/error.service";
import {EditorSpaceComponent} from "../editor-space/editor-space.component";
import {ProjectCountComponent} from "../stat-blocks/project-count/project-count.component";
import {ProjectWordCountComponent} from "../stat-blocks/project-word-count/project-word-count.component";
import {UserCountComponent} from "../stat-blocks/user-count/user-count.component";
import {UserWordCountComponent} from "../stat-blocks/user-word-count/user-word-count.component";
import {PlaceholderComponent} from "../stat-blocks/placeholder/placeholder.component";

@Component({
    templateUrl: './writer-dashboard.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ChartModule,
        MenuModule,
        TableModule,
        StyleClassModule,
        PanelMenuModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        FormsModule,
        ProjectDescriptorComponent,
        InputTextareaModule,
        BetaReaderReadingProfileComponent,
        ProjectProfileComponent,
        WriterResourceMatchesComponent,
        EditorSpaceComponent,
        ProjectCountComponent,
        ProjectWordCountComponent,
        UserCountComponent,
        UserWordCountComponent,
        PlaceholderComponent,
    ]
})
export class WriterDashboardComponent {

    protected platformUser: PlatformUser;
    protected writer: WriterProfile;
    protected selectedProject: Project;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                public layoutService: LayoutService,
                errorService: ErrorService) {
        console.log("WriterDashboard - errorService: ", errorService);
    }

    ngOnInit() {
        this.subscriptions.push(this.dataService.user.subscribe(data => {
            this.platformUser = data;

            if (!this.platformUser.roles.includes(PlatformRole.WRITER.valueOf())) {
                this.dataService.redirectToHome();
            }
        }));
        this.subscriptions.push(this.dataService.writer.subscribe(async writer => {
            this.writer = writer;
            this.subscriptions.push(this.route.params.subscribe(async params => {
                console.log("Got Route in WriterDashboard - params: ", params);
                const uuid : string = params['project'];
                console.log("UUID: ", uuid);
                if (uuid === "new") {
                    //Build a new project
                    this.selectedProject = this.dataService.newProject(this.writer);
                    this.dataService.setSelectedProject(undefined);
                } else {
                    //Load the project
                    this.selectedProject = this.writer.projects.items.find(p => p.uuid === uuid);
                    this.dataService.setSelectedProject(this.selectedProject);
                    //this.selectedProject = await this.dataService.getProject(uuid);
                    console.log("SelectedProject: ", this.selectedProject);
                }

            }));
        }));
    }

    private subscriptions: Subscription[] = [];
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
}

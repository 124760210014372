
<div class="grid" *ngIf="processing">
    <div class="col-12 center w-full h-full">
        <p-progressSpinner class="flex w-full h-full align-self-center align-items-center justify-content-center"></p-progressSpinner>
    </div>
</div>
<div class="grid" *ngIf="!processing">
    <div class="col-12">
        <div class="card">
            <div class="surface-ground px-0 py-8 md:px-6 lg:px-8">
                <div class="text-900 font-bold text-6xl mb-4 text-center">I am a&hellip;</div>
                <div class="text-700 text-xl mb-6 text-center line-height-3">Choose which role you'd like to start with. You can always add others later!</div>

                <div class="grid">
                    <div class="col-12 lg:col-4">
                        <div class="p-3 h-full">
                            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                                <div class="grid relative">
                                    <div class="md:hidden col-12 mt-4 flex justify-content-end pb-0 -translate-y-100 absolute">
                                        <img src="../../../assets/badges/writer.png"/>
                                    </div>
                                    <div class="col-12 md:col pb-0">
                                        <div class="text-900 font-medium text-xl mb-2">Writer</div>
                                        <div class="text-600">I'm looking for beta readers</div>
                                    </div>
                                    <div class="col-fixed pr-3 pb-0 hidden md:inline origin-top-right relative" style="max-width: 128px">
                                        <img src="../../../assets/badges/writer.png" class="pr-3 absolute" style="right:0px"/>
                                    </div>
                                </div>
                                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                                <div class="flex align-items-center">
                                    <span class="font-bold text-2xl text-900">Free</span>
                                    <span class="ml-2 font-medium text-600"> (always)</span>
                                </div>
                                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                                <ul class="list-none p-0 m-0 flex-grow-1">
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Submit to the beta-reading project catalogue</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Match with readers interested in your book</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Get feedback on your story</span>
                                    </li>
                                </ul>
                                <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                                <button pButton pRipple label="This is me" class="p-3 w-full  p-button-outlined" (click)="addRoles([PlatformRole.WRITER])"></button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 lg:col-4">
                        <div class="p-3 h-full">
                            <div class="shadow-2 p-3 flex flex-column surface-card" style="border-radius: 6px">
                                <div class="grid relative">
                                    <div class="md:hidden col-12 mt-4 flex justify-content-end pb-0 -translate-y-100 absolute">
                                        <img src="../../../assets/badges/beta_reader.png"/>
                                    </div>
                                    <div class="col-12 md:col pb-0">
                                        <div class="text-900 font-medium text-xl mb-2">Beta Reader</div>
                                        <div class="text-600">I'm looking for interesting projects to read</div>
                                    </div>
                                    <div class="col-fixed pr-3 pb-0 hidden md:inline origin-top-right relative" style="max-width: 128px">
                                        <img src="../../../assets/badges/beta_reader.png" class="pr-3 absolute" style="right:0px"/>
                                    </div>
                                </div>
                                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                                <div class="flex align-items-center">
                                    <span class="font-bold text-2xl text-900">Free</span>
                                    <span class="ml-2 font-medium text-600"> (always)</span>
                                </div>
                                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                                <ul class="list-none p-0 m-0 flex-grow-1">
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Find new, free stories to read</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Match with super-cool projects that interest you</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Filter out stories that aren't your speed</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Tell the writer what you think!</span>
                                    </li>
                                </ul>
                                <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                                <button pButton pRipple label="This is me" class="p-3 w-full p-button-outlined" (click)="addRoles([PlatformRole.BETAREADER])"></button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 lg:col-4">
                        <div class="p-3 h-full">
                            <div class="shadow-2 p-3 flex flex-column surface-card" style="border-radius: 6px">
                                <div class="grid relative">
                                    <div class="md:hidden col-12 mt-4 pb-0 -translate-y-100 justify-content-end absolute" style="max-width:128px;top:-64px;right:0;">
                                        <img src="../../../assets/badges/writer.png" class="pr-3 absolute" style="right:30px"/>
                                        <img src="../../../assets/badges/beta_reader.png" class="pr-3 absolute" style="right:15px"/>
                                        <img src="../../../assets/badges/superhero.png" class="pr-3 absolute" style="right:0px"/>
                                    </div>
                                    <div class="col pb-0">
                                        <div class="text-900 font-medium text-xl mb-2">Flippin' Superhero!</div>
                                        <div class="text-600">Sign me up as a writer and a beta reader</div>
                                    </div>
                                    <div class="col-fixed pr-3 pb-0 hidden md:inline relative" style="max-width:128px">
                                        <img src="../../../assets/badges/writer.png" class="pr-3 absolute" style="right:30px"/>
                                        <img src="../../../assets/badges/beta_reader.png" class="pr-3 absolute" style="right:15px"/>
                                        <img src="../../../assets/badges/superhero.png" class="pr-3 absolute" style="right:0px"/>
                                    </div>
                                </div>
                                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                                <div class="flex align-items-center">
                                    <span class="font-bold text-2xl text-900">Free</span>
                                    <span class="ml-2 font-medium text-600"> (always)</span>
                                </div>
                                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                                <ul class="list-none p-0 m-0 flex-grow-1">
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Submit to the beta-reading project calalogue</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Match with reading projects that interest you</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">Basically, you get both of the other roles</span>
                                    </li>
                                    <li class="flex align-items-center mb-3">
                                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span class="text-900">A superhero badge on your user profile</span>
                                    </li>
                                </ul>
                                <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                                <button pButton pRipple label="This is me" class="p-3 w-full mt-auto" (click)="addRoles([PlatformRole.WRITER, PlatformRole.BETAREADER])"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


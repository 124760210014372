<div *ngIf="project.id && (!writerResourceMatches || Object.keys(writerResourceMatches).length === 0)">
    <strong>Unfortunately, there aren't any new resource matches for your project - check back soon!</strong>
</div>
<div *ngIf="writerResourceMatches && Object.keys(writerResourceMatches).length > 0">
    <p-dataView #dv [value]="writerResourceMatchesArray" [paginator]="writerResourceMatches.size > 9" [rows]="9" filterBy="project.title" [sortField]="sortField" [sortOrder]="sortOrder">
            <ng-template pTemplate="header">
                <div class="flex flex-column md:flex-row md:justify-content-between gap-2">
                </div>
            </ng-template>

            <ng-template let-projectMatches pTemplate="list">
                <div class="col-12" *ngFor="let projectMatch of projectMatches">
                    <div *ngIf="projectMatch.project" class="flex flex-column md:flex-row align-items-center p-3 w-full">
                        <img height="64px" [src]="'assets/book_image_placeholder.png'" [alt]="projectMatch.project.name" class="my-4 md:my-0 shadow-2 mr-5"/>
                        <div class="flex-1 flex flex-column align-items-center text-center md:text-left">
                            <div class="font-bold text-2xl">{{projectMatch.resourceType.valueOf()}}</div>
                            <div class="mb-2">{{projectMatch.name}}</div>
                            <!-- p-rating [readonly]="true" [cancel]="false" styleClass="mb-2"></p-rating -->
                            <div class="flex align-items-center mt-2">
                                <span *ngFor="let tag of matchMeta.get(projectMatch.project.uuid).tags">
                                    <i [class]="'pi mr-2 '+tag.icon"></i>
                                    <span class="font-semibold">{{tag.label}}</span>
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
                            <span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end">{{projectMatch.bio_sketch}}</span>
                            <p-button *ngIf="!matchMeta.get(projectMatch.project.uuid).showDetail" [raised]="true" icon="pi pi-info-circle" label="See More" (click)="matchMeta.get(projectMatch.project.uuid).showDetail=true" styleClass="mb-2 p-button-sm"></p-button>
                            <p-button *ngIf="matchMeta.get(projectMatch.project.uuid).showDetail" icon="pi pi-info-circle" label="Close Detail" (click)="matchMeta.get(projectMatch.project.uuid).showDetail=false" styleClass="mb-2 p-button-sm"></p-button>
                            <span [class]="'project-badge status-' + projectMatch.project.beta_reader_status?.toLowerCase()">{{projectMatch.project.beta_reader_status==BetaReaderStatus.PENDING_RESPONSE?'Interested':projectMatch.project.beta_reader_status}}</span>
                        </div>
                    </div>
                    <hr/>
                    <resource-detail [resourceMatch]="projectMatch" *ngIf="matchMeta.get(projectMatch.project.uuid).showDetail"></resource-detail>
                </div>
            </ng-template>
        </p-dataView>
</div>

import Quill, {Parchment} from "quill";
import {Scope} from 'parchment';
import {CommentBlot} from "./CommentBlot";
import {EditorComponent} from "../editor/editor.component";
import {PlatformUser} from "../../service/db/entities/PlatformUser";

export class RNGComments {
    private static quill: any;
    private options: any;

    public static isEnabled: boolean = true; // Can you see ANY comments?

    private CommentID = new Parchment.ClassAttributor('comment-id','comment-id',{ scope: Scope.ATTRIBUTE });

    //private CommentData = new Parchment.ClassAttributor('comment-data', 'ql-commentblot', {scope: Parchment.Scope.INLINE});


    private editorComponent: EditorComponent;

    constructor(quillOb, options) {
        RNGComments.quill = quillOb;
        this.options = options;

        Quill.register(CommentBlot, true);
        Quill.register(this.CommentID, true);
        //Quill.register(this.CommentData, true);

        let commentAddClick = this.options.commentAddClick;
        let commentToggleUpdate = this.options.commentToggleUpdate;
        this.editorComponent = this.options.editorComponent;
        this.editorComponent.setReferenceToCommentModule(this);
        let addComment = this.addComment;

        let commentObj = this;

        // for comment color on/off toolbar item
        let toolbar = RNGComments.quill.getModule('toolbar');
        if (toolbar) {
            toolbar.addHandler('comments-toggle', function() {

                commentObj.enable(!RNGComments.isEnabled);

                const collection = document.getElementsByClassName('rng-comment'); // all
                for (let i = 0; i < collection.length; i++) {
                    const node = collection[i];
                    const comment = JSON.parse(node.getAttribute('comment-data'));
                    CommentBlot.toggleComment(RNGComments.isEnabled, comment, node);
                }

                if (commentToggleUpdate) {
                    commentToggleUpdate(RNGComments.isEnabled);
                }
            });
            toolbar.addHandler('comments-add', function() {
                this.range = RNGComments.quill.getSelection();

                if (!this.range || this.range.length ==0) {
                    return; // do nth, cuz nothing is selected
                }
                commentAddClick(addComment,this);
            });

        } else {
            console.log('Error: WPSQLComments.quill-comment module needs WPSQLComments.quill toolbar');
        }

        // to prevent comments from being copied/pasted.
        RNGComments.quill.clipboard.addMatcher('mark[ql-comment]', function(node, delta) {
            delta.ops.forEach(function(op) {
                op.attributes["comment-data"] && delete op.attributes["comment-data"];
            });
            return delta;
        });
    }

    async updateCommentReplies(comment: Comment) {
        //WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentReplies', JSON.stringify(comment.replies));
        //Need to update the comment Blot so it removes the format when removed and update it
        const el = document.getElementById('RNGCOMMENT-'+comment.uuid);
        const commentStr: string = JSON.stringify(comment);
        //el.setAttribute('comment-data', commentStr);

        let delta = RNGComments.quill.getContents();
        let fragment = delta.ops.find(fragment => {
            return (fragment.attributes?.comment?.indexOf(comment.uuid) > -1);
        });
        fragment.attributes['comment'] = commentStr;

        RNGComments.quill.setContents(delta,'api');
        this.editorComponent.update();
    }

    removeComment(comment: Comment) {
        if (!comment || !comment.comment) {
            return; // cannot work without comment
        }

        /*WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'background', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentfg', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorUUID', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorScreenName', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorRole', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentIcon', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentTimestamp', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentId', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'comment', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentReplies', false);
        WPSQLComments.quill.deleteAt(comment.range.index+comment.range.length,1,Quill.sources.USER);*/
    }

    addComment(comment: Comment) {

        if (!comment || !comment.comment) {
            return; // cannot work without comment
        }


        if (!comment.replies) {
            comment.replies = [];
        }
        RNGComments.quill.format('comment', JSON.stringify(comment), 'api');
        //WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'bold', true);

        //setTimeout(() => WPSQLComments.quill.setSelection(comment.range.index), 0);
    }

    enable(enabled = true) {
        RNGComments.isEnabled = enabled;
    }

    disable() {
        this.enable(false);
    }

}


export class CommentView {
    public uuid: string;
    public screenName: string;
    public timestamp: Date;
}

export class BaseComment {

    public hidden: boolean = false;
    public comment: string;
    public authorUUID: string;
    public authorRole: string;
    public authorScreenName: string;
    public colorIndex: number;
    public timestamp: Date;
    public replies: Reply[];
    public iconHTML: string;
    public userFirstViews: CommentView[];

    public constructor(params) {
        this.hidden = params.hidden;
        this.comment = params.comment;
        this.authorUUID = params.authorUUID;
        this.authorRole = params.authorRole;
        this.authorScreenName = params.authorScreenName;
        this.colorIndex = params.colorIndex;
        this.timestamp = params.timestamp;
        this.replies = params.replies;
        this.iconHTML = params.iconHTML;
        this.userFirstViews = params.userFirstViews;
    }

    public static markViewed(comment: BaseComment, user: PlatformUser){
        if ( ! comment.userFirstViews ) {
            comment.userFirstViews = [];
        }
        if ( comment.authorUUID === user.uuid || comment.userFirstViews.findIndex((v: CommentView) =>  v.uuid === user.uuid) !== -1 ) {
            return; //Nothing to update.
        }
        comment.userFirstViews.push({
            screenName: user.screenName,
            timestamp: new Date(),
            uuid: user.uuid
        });
    }

}

export class Comment extends BaseComment {

    public constructor(params) {
        super(params);
        this.range = params.range;
        this.callback = params.callback;
        this.uuid = params.uuid;
    }

    public range: {index: number | null, length: number | null};
    public callback: any;
    public uuid: string;
}

export class Reply extends BaseComment {
    //Nothing new to add here, yet

    public constructor(params) {
        super(params);
    }
}


import {Component, Input} from '@angular/core';
import {ChipModule} from "primeng/chip";
import {ProjectMatchType, DataService, WriterResourceMatchType} from "../../service/data.service";
import {NgForOf, NgIf} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {BetaReaderStatus, WriterStatus} from "../../api/enums";
import {MessageService} from "primeng/api";

@Component({
  selector: 'resource-detail',
  standalone: true,
    imports: [
        ChipModule,
        NgForOf,
        NgIf,
        ButtonModule,
        RippleModule
    ],
  templateUrl: './resource-detail.component.html',
  styleUrl: './resource-detail.component.scss'
})
export class ResourceDetailComponent {
    @Input() resourceMatch!: WriterResourceMatchType;

    protected readonly Array = Array;

    constructor(private messageService: MessageService, private dataService: DataService) {
    }

    isArray(value: string | string[]) {
        return value instanceof Array;
    }

    async setAccept() {
        console.log('BetaReaderProject Save: ',this.resourceMatch.project);
        if (!this.resourceMatch.project.id) {
            await this.dataService.saveBetaReaderProject(this.resourceMatch.project);
        }
        await this.dataService.setBetaReaderStatus(this.resourceMatch.project, BetaReaderStatus.ACTIVE);
        await this.dataService.setBRPWriterStatus(this.resourceMatch.project, WriterStatus.ACTIVE)
        this.resourceMatch.project.betaReaderStatus = BetaReaderStatus.ACTIVE;
        this.resourceMatch.project.writerStatus = WriterStatus.ACTIVE;
        this.messageService.add({ severity: 'info', summary: 'Beta Reader Accepted', detail: 'We\'ve notified the beta reader of their acceptance for this project.' });
    }

    async setNotInterested() {
        console.log('BetaReaderProject Save: ',this.resourceMatch.project);
        if (!this.resourceMatch.project.id) {
            await this.dataService.saveBetaReaderProject(this.resourceMatch.project);
        }
        await this.dataService.setBetaReaderStatus(this.resourceMatch.project, null);
        await this.dataService.setBRPWriterStatus(this.resourceMatch.project, WriterStatus.NOT_INTERESTED)
        this.resourceMatch.project.betaReaderStatus = null;
        this.resourceMatch.project.writerStatus = WriterStatus.NOT_INTERESTED;
        this.messageService.add({ severity: 'info', summary: 'Beta Reader Status Updated', detail: 'We have registered "Not Interested"' });
    }

    protected readonly BetaReaderStatus = BetaReaderStatus;
}

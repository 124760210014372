import {Component, NgZone, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {NgIf} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {DataService, RoleProject} from "../../service/data.service";
import {DialogModule} from "primeng/dialog";
import {BadgeModule} from "primeng/badge";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PaginatorModule} from "primeng/paginator";
import {v4 as uuid} from "uuid";
import Quill from "quill";
import {Comment, Reply, RNGComments} from "../comment/RNG_Comments";
import {QuillEditorComponent} from "ngx-quill";
import {InputSwitchModule} from "primeng/inputswitch";
import {CommentComponent} from "../comment/comment.component";
import {PlatformRole} from "../../api/enums";
import {RNGQLAutosave} from "./RNG_QL_Autosave";
import {ErrorService, LogProvider} from "../../service/error.service";
import {RNGSectionModule} from "../contents-management/RNG_Section_Module";
import {DocumentMetadata} from "../contents-management/MetadataBlot";
import {ProjectSchema} from "../../api/project_schemas";
import {DocumentService, TableOfContents} from "../../service/document.service";
import {Delta} from "quill/core";
import {Section, SectionBlot} from "../contents-management/SectionBlot";
import { ProgressService } from 'src/app/service/progress.service';
import {Project} from "../../service/db/entities/Project";

@Component({
    selector: 'editor',
    standalone: true,
    templateUrl: './editor.component.html',
    imports: [
        NgIf,
        ButtonModule,
        DialogModule,
        BadgeModule,
        PaginatorModule,
        QuillEditorComponent,
        InputSwitchModule,
        CommentComponent
    ],
    styleUrl: './editor.component.scss'
})
export class EditorComponent implements OnInit, OnDestroy {

    private static readonly LOG = LogProvider.getLogger('EditorComponent');

    public static quill: any;
    private static progressService: ProgressService;
    private static documentService: DocumentService;

    private referenceToAutosaveModule: RNGQLAutosave;
    private referenceToCommentModule: RNGComments;
    private referenceToSectionModule: RNGSectionModule;

    private roToolbarContainer = [
        ['force-save'],
        ['comments-toggle', 'comments-add']
    ];

    private rwToolbarContainer = [
        ['force-save'],
        ['bold', 'italic', 'underline', 'strike'],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
        [{'align': []}],
        [{'font': []}, {size: ['small', false, 'large', 'huge']}],
        ['comments-toggle', 'comments-add'],
        ['clean']
    ];

    protected isReadOnly: boolean;
    public toolbarContainer: any = null;

    protected uuid: string;

    protected newReply: Reply;


    private roToolbar = {
        container: [
            ['force-save'],
            ['comments-toggle', 'comments-add']
        ],
        handlers: {
            'comments-toggle': () => {
                console.log('toggle was clicked')
            },
            'comments-add': () => {
                console.log('add was clicked')
            },
            'force-save': () => {
                console.log('force save was clicked')
            }
        }
    };

    private rwToolbar = {
        container: [
            ['force-save'],
            ['bold', 'italic', 'underline', 'strike'],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
            [{'align': []}],
            [{'font': []}, {size: ['small', false, 'large', 'huge']}],
            ['comments-toggle', 'comments-add'],
            ['clean']
        ],
        handlers: {
            'comments-toggle': () => {
                console.log('toggle was clicked')
            },
            'comments-add': () => {
                console.log('add was clicked')
            },
            'force-save': () => {
                console.log('force save was clicked')
            }
        }
    };

    //private defaultPS: ProjectSchema = ProjectSchemas.DEFAULT_PROJECT_SCHEMA;

    protected modules: any = {
        toolbar: this.roToolbar,
        comments: {
            enabled: true,
            commentAddClick: this.commentAddClick, // get called when `ADD COMMENT` btn on options bar is clicked
            commentToggleUpdate: this.commentsToggle, // get called when you click `COMMENTS` btn on options bar for you to do additional things beside color on/off. Color on/off is already done before the callback is called.
            editorComponent: this
        },
        autosave: {
            onForceSaveClickCallback: this.onForceSaveClickCallback,
            onDocumentDirtyCallback: this.onDocumentDirtyCallback,
            updateFullExternalDocument: this.updateFullExternalDocument,
            onDocumentSaved: this.onDocumentSaved,
            editorComponent: this,
            timeout: 5000
        },
        sections: {
            divideSectionClick: this.onDivideSection,
            editorComponent: this
        }
    };


    static userColors: { background: string, foreground: string }[] = [
        {
            background: '#ffcccc',
            foreground: '#000000'
        },
        {
            background: '#ccffcc',
            foreground: '#000000'
        },
        {
            background: '#ccccff',
            foreground: '#000000'
        },
        {
            background: '#ffffcc',
            foreground: '#000000'
        },
        {
            background: '#ccffff',
            foreground: '#000000'
        },
        {
            background: '#ffccff',
            foreground: '#000000'
        },
        {
            background: '#ffe0cc',
            foreground: '#000000'
        },
        {
            background: '#e0ffe0',
            foreground: '#000000'
        },
        {
            background: '#e0ccff',
            foreground: '#000000'
        },
        {
            background: '#ffcce0',
            foreground: '#000000'
        },
        {
            background: '#e0cce0',
            foreground: '#000000'
        },
    ];
    private tempDeltaContent: Delta;

    get userColors() {
        return EditorComponent.userColors;
    }

    private subscriptions: Subscription[] = [];

    //Need to know what role I have on this specific project
    protected showComments = signal(false);
    protected showMultiple: boolean = false;

    protected static showAddCommentDialog = signal(false);
    get showAddCommentDialog() {
        return EditorComponent.showAddCommentDialog;
    }

    //set showAddCommentDialog(sacd: boolean) { EditorComponent.showAddCommentDialog = sacd; }

    protected static selectedComment = signal<Comment>(null);
    get selectedComment() {
        return EditorComponent.selectedComment;
    }

    private static roleProject: RoleProject;
    get roleProject() {
        return EditorComponent.roleProject;
    }

    static EDITOR_COMPONENT: EditorComponent;

    public static platformUser = null;

    public static readyToInitializeQuill: WritableSignal<boolean> = signal(false);
    private static disableAutosave: boolean = false;

    constructor(private route: ActivatedRoute,
                private errorService: ErrorService,
                protected dataService: DataService,
                private documentService: DocumentService,
                private progressService: ProgressService,
                private zone: NgZone) {

        EditorComponent.EDITOR_COMPONENT = this;
        EditorComponent.progressService = progressService;
        EditorComponent.documentService = documentService;

        window['angularComponentRef'] = {
            zone: this.zone,
            showComment: (comment, offset) => {
                this.displayComment(comment, offset)
            },
            component: this
        }

        const icons = Quill.import('ui/icons');

        if (!Quill.import('modules/comments')) {
            Quill.register('modules/comments', RNGComments);
            icons['comments-toggle'] = '<i id="commentsToggleButton" class="pi pi-eye" aria-hidden="true"></i>';
            icons['comments-add'] = '<i id="commentsAdButton" class="pi pi-comment" aria-hidden="true"></i>';
        }
        if (!Quill.import('modules/autosave')) {
            Quill.register('modules/autosave', RNGQLAutosave);
            icons['force-save'] = '<i id="forceSaveButton" class="pi pi-save" aria-hidden="true"></i>';
        }

        if (!Quill.import('modules/sections')) {
            Quill.register('modules/sections', RNGSectionModule);
        }

        //Quill.debug('info');
        Quill.debug(false);


        // this.subscriptions.push(this.route.params.subscribe(async params => {
        //     //console.log(params);
        //     this.uuid = params['project'];
        //     //Need this to return to the previous url
        //     //console.log('DEBUG: Initial: ', params['retUrl']);
        //     //console.log('DEBUG: decodeURI: ', decodeURI(params['retUrl']));
        //     //console.log('DEBUG: b64 decode: ', atob(decodeURI(params['retUrl'])));
        //     this.dataService.redirectUrl = atob(decodeURI(params['retUrl']));
        //     const prj: Project = this.dataService.getProjectForUUID(this.uuid);
        //     this.dataService.setSelectedProject(prj);
        //     EditorComponent.roleProject = this.dataService.getRoleBasedProjectForUUID(this.uuid);
        //     if (!prj.currentDeltaContent) {
        //         this.dataService.switchToBaseLayout();
        //     }
        // }));
    }

    async onEditorCreated(quill) {

        console.log("EditorCreated");
        console.log(quill);
        EditorComponent.quill = quill;

        //TODO: This is where the load happens when the screen just gets flipped.
        // Somehow the loaded content gets lost when it flips back
        // Either we can keep the content loaded (resource problem) or load faster
        // and not show the editor unless it is loaded from the db.
        // tempContent might be checked - so either the editor has content or tempContent
        // has content or the frame is hidden?

        console.log('CHECKING LOAD ------------------------------------------------', this.tempDeltaContent);
        if (!this.tempDeltaContent) {
            console.log('FORCING LOAD (What a hack...)  -----------------------------------------------------------------');
            await this.onLoad();
        }

        const proj = this.documentService.selectedProject;
        if (!proj) { //wait till we have a project
            return;
        }

        //Retrieve the content from the temp loader
        let delta: Delta = this.tempDeltaContent;
        this.tempDeltaContent = null;

        EditorComponent.LOG.debug('delta to load: ', delta);
        EditorComponent.LOG.debug('delta on load: ', await EditorComponent.displayContent(delta));
        let toc: TableOfContents = await this.documentService.getTOC(delta);
        //toc = await this.documentService.checkTOC(proj, toc);
        EditorComponent.EDITOR_COMPONENT.update();
        //EditorComponent.LOG.debug('delta on cleanup-load: ', await EditorComponent.displayContent(delta));

        EditorComponent.quill.history.clear(); //Don't allow undo that removes all content.

        this.referenceToSectionModule.onAngularInit(proj, DocumentService.documentMetadata.projectSchema); //Everything is set up on this side, so now the section can finish its setup.
        EditorComponent.quill.on(Quill.events.TEXT_CHANGE, this.update.bind(this));
        EditorComponent.LOG.debug('Leaving setup: ', toc, EditorComponent.quill.getContents());
    }

    update() { //Called when the content of the editor is changed by a user
        if (!EditorComponent.disableAutosave) {
            this.referenceToAutosaveModule.flagDocumentDirty();
        }
        /* const length = this.calculate();
        let label = this.options.unit;
        if (length !== 1) {
            label += 's';
        }
        this.container.innerText = `${length} ${label}`; */
        //console.log('Update Called on Comment Plugin');
        //console.log(JSON.stringify(WPSQLComments.quill.getContents()));
    }

    static async displayContent(delta: Delta): Promise<Delta> {
        //Don't mark as dirty for a load . . .
        this.progressService.blockWithMessage('Loading section');
        this.disableAutosave = true;
        const ret = this.quill.setContents(delta);
        this.disableAutosave = false;
        this.progressService.unBlock();
        return ret;
    }

    async onDivideSection(callback, value: Section) {
        value.uuid = uuid();
        console.log("caretIndex", EditorComponent.quill.getSelection(true));
        value.caretIndex = EditorComponent.quill.getSelection(true).index;

        EditorComponent.LOG.debug('Divide Section Called! Incoming Value: ', value);
        EditorComponent.LOG.debug('This: ', this);
        EditorComponent.LOG.debug('Initial TOC: ', DocumentService.tableOfContents);

        const splitIndex = EditorComponent.quill.getSelection(true).index;
        EditorComponent.LOG.debug('SplitIndex: ', splitIndex);

        if (!splitIndex) {
            return; // do nth, cuz nothing is selected
        }

        if (value.level <= 0) {
            //NOPE - how did you do this anyway?
            this.errorService.handleError({
                detail: "Divide section called on or above(?!?) top level - disallowed.",
                severity: "SEVERE",
                stack: undefined,
                summary: "Divide section called on or above(?!?) top level - disallowed. Please report this to support."
            });
            return;
        }

        let toc = DocumentService.tableOfContents;
        let dm: DocumentMetadata = DocumentService.documentMetadata;

        //check for SPECIAL SITUATION - dividing at project schema level - lower the level by one and add a new initial section
        //TODO - THIS DOESN"T MATTER, RIGHT? WE DON"T NEED TO DO THIS?
        if (value.level === dm.projectSchema.projectLevel) {
            dm.projectSchema.projectLevel = dm.projectSchema.projectLevel - 1;
            const s: Section = {
                uuid: uuid(),
                level: dm.projectSchema.projectLevel,
                name: null,
                metadata: []
            };
            EditorComponent.quill.insertEmbed(1, 'section', JSON.stringify(s), 'api');
            this.documentService.saveDocumentMetadata(dm);
        }
        EditorComponent.quill.insertEmbed(value.caretIndex, 'section', JSON.stringify(value), 'api');
        this.documentService.getTOC(EditorComponent.quill.getContents());

        callback(value);
    }


    // console.log('Divide Section Called! Current Value: ', value);
    //
    // if (value.level <= 0) {
    //     //NOPE - how did you do this anyway?
    //     this.errorService.handleError({
    //         detail: "Divide section called on or below top level - disallowed.",
    //         severity: "SEVERE",
    //         stack: undefined,
    //         summary: "Divide section called on or below top level - disallowed. Please report this to support."
    //     });
    //     return;
    // }
    //
    // const currentSection = this.dataService.currentSection();
    // let parentSectionLink = this.getParentSectionLinkFromTOC(this.dataService.currentSection());
    // let parentLinks = parentSectionLink.childLinks;
    //
    // //Special case - no parent
    // if ( ! parentLinks ) {
    //     let section: Section = {
    //         latexCode: value.latexCode,
    //         uuid: uuid(),
    //         level: value.level,
    //         name: value.sectionName + ' 2',
    //         metadata: []
    //     };
    //
    //     if (currentSection.level === value.level) { //This is a split that creates a sibling. In this case, adjust the top level.
    //
    //         //First, do the TOC update
    //         const toc = this.getTableOfContents();
    //         const schema = toc.projectSchema; this.getProjectSchema();
    //
    //         const newTop: Section = {
    //             latexCode: schema.sectionLayout[value.level-1].latexCode,
    //             uuid: uuid(),
    //             level: value.level-1,
    //             name: schema.sectionLayout[value.level-1].sectionName,
    //             metadata: []
    //         };
    //
    //         const oldTopLevelSectionLink = toc.topLevelSectionLink;
    //
    //         const newTopLink: SectionLink = {
    //             childLinks: [oldTopLevelSectionLink],
    //             section: newTop
    //         };
    //         toc.topLevelSectionLink = newTopLink;
    //         toc.projectSchema.projectLevel = value;
    //
    //         //Second, is it the easy kind? No content?
    //         if (this.getProjectSchema()[value.level-1].hasContent) {
    //             //Nope - now we have to split and adjust types.
    //
    //             EditorComponent.quill.editor.insertEmbed(0, 'section', JSON.stringify(oldTopLevelSectionLink.section), Quill.sources.API);
    //
    //         }
    //
    //     }
    // }
    //
    //
    //     let section: Section = {
    //         latexCode: value.latexCode,
    //         uuid: uuid(),
    //         level: value.level,
    //         name: value.sectionName + ' ' + parentLinks.length + 1,
    //         metadata: []
    //     };
    //
    //     //Insert after the current section's record in the array - Except that may need to pop up to a parent if the level is wrong. . . .
    //     if (currentSection.level === value.level) { //This is a split that creates a sibling.
    //         for (let i: number = 0; i < parentLinks.length; i++) {
    //             if (parentLinks[i].section.uuid === currentSection.uuid) {
    //                 parentLinks.splice(i + 1, 0, {section: section, childLinks: []});
    //                 break;
    //             }
    //         }
    //     } else if (currentSection.level < value.level ) { //We are splitting the current section further - child split
    //         for (let i: number = 0; i < parentLinks.length; i++) {
    //             if (parentLinks[i].section.uuid === currentSection.uuid) {
    //                 parentLinks[i].childLinks.splice(0, 0, {section: section, childLinks: []});
    //                 break;
    //             }
    //         }
    //     } else { //So this is a split that will live at a higher level. We need to walk the tree up until we find the right level to add it. If that level doesn't exist, we can stick it under the nearest match.
    //         let potentialParentSectionLink = parentSectionLink;
    //         let potentialSiblingSection = parentSectionLink.section;
    //         while (potentialParentSectionLink.section.level > value.level) {  //Walk up the tree until we are equal to or above.
    //             potentialSiblingSection = potentialParentSectionLink.section;
    //             potentialParentSectionLink = this.getParentSectionLinkFromTOC(potentialSiblingSection);
    //         }
    //         //Now we are where we need to insert
    //         potentialParentSectionLink.childLinks.splice(potentialParentSectionLink.childLinks.findIndex(cl => cl.section.uuid===potentialSiblingSection.uuid)+1, 0,  {section: section, childLinks: []});
    //     }
    //     callback(section);
    // }

    //private async splitDocument(lastIndexToKeep: number): Promise<Delta> {
    //    const retDelta = EditorComponent.quill.scroll.getContents(lastIndexToKeep+1);
    //    EditorComponent.quill.scroll.setContents(EditorComponent.quill.scroll.getContents(0, lastIndexToKeep+1));
    //    await this.referenceToAutosaveModule.forceSave(this.referenceToAutosaveModule);
    //    return retDelta;
    //}


    // private getParentSectionLinkFromTOC(child: Section): SectionLink {
    //     const toc = this.dataService.tableOfContents().topLevelSectionLink;
    //     if (child.uuid === toc.section.uuid) {
    //         return null; //No parent - this is the top
    //     }
    //     return this.getParentSectionLink(child, toc);
    // }


    // private getParentSectionLink(child: Section, possibleParent: SectionLink): SectionLink {
    //
    //     if ( possibleParent.childLinks.findIndex( sl => sl.section.uuid === child.uuid ) > 0 ) {
    //         return possibleParent; //Found it, so this is the parent group;
    //     }
    //     for ( let link of possibleParent.childLinks ) {
    //         const sl = this.getParentSectionLink(child, link);
    //         if (sl) {
    //             //Found it - let it bubble up
    //             return sl;
    //         }
    //     }
    //     return null; //Not on this branch
    //
    // }

    commentAddClick(callback, wpsQlCommentsOb) {
        //console.log("AddComment Triggered");
        //NOTE: "this" is not set when Quill calls this function!
        //console.log("WPSQLComments: ", wpsQlCommentsOb);
        let colorIdx = EditorComponent.roleProject.colorIndex;


        let comment: Comment = new Comment({
            colorIndex: colorIdx,
            iconHTML: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? '<i class="comment-icon pi pi-comment"></i>' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? '<i class="comment-icon pi pi-pencil"></i>' :
                        'error' //No Role Project
            ),
            authorRole: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'Beta Reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'Author' :
                        'error' //No Role Project
            ),
            comment: null,
            authorUUID: DataService.s_user.uuid,
            authorScreenName: DataService.s_user.screenName,
            timestamp: new Date(),
            hidden: false,
            replies: null,
            uuid: uuid(),
            userFirstViews: [],
            range: wpsQlCommentsOb.quill.getSelection(),
            callback: callback
        });
        EditorComponent.selectedComment.set(comment);
        EditorComponent.showAddCommentDialog.set(true);
    }

    commentsToggle(enabled: boolean) {
        // comments btn callback
        console.log("CommentsToggle Triggered");

        const el = document.getElementById("commentsToggleButton");
        el.className = "pi " + (enabled ? "pi-eye" : "pi-eye-slash");
        const css = document.getElementById("ql-comment-visible");
    }

    displayComment(comment, offset) {
        const commentData = comment.domNode.attributes['comment-data'].value;
        let commentOb = <Comment>JSON.parse(commentData);
        EditorComponent.selectedComment.set(commentOb);
        //console.log('Multi: ', this.showMultiple);
        let colorIdx = EditorComponent.roleProject.colorIndex;
        this.newReply = new Reply({
            colorIndex: colorIdx,
            iconHTML: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? '<i class="comment-icon pi pi-comment"></i>' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? '<i class="comment-icon pi pi-pencil"></i>' :
                        'error' //No Role Project
            ),
            authorRole: (
                (EditorComponent.roleProject.role === PlatformRole.BETAREADER) ? 'Beta Reader' :
                    (EditorComponent.roleProject.role === PlatformRole.WRITER) ? 'Author' :
                        'error' //No Role Project
            ),
            comment: null,
            authorUUID: this.dataService.user().uuid,
            authorScreenName: this.dataService.user().screenName,
            timestamp: new Date(),
            hidden: false,
            userFirstViews: [],
            replies: null
        });
        this.showComments.set(true);
    }

    closeComment() {
        EditorComponent.showAddCommentDialog.set(false);
        this.showComments.set(false);

    }

    saveNewComment() {
        EditorComponent.showAddCommentDialog.set(false);
        EditorComponent.selectedComment().callback(EditorComponent.selectedComment());
    }

    onForceSaveClickCallback(autoSaveOb) {
    }

    onDocumentDirtyCallback(autoSaveOb) {
        const el = document.getElementById("forceSaveButton");
        if (el) {
            el.style.color = "orange";
        }
    }

    async updateFullExternalDocument(delta: Delta, autoSaveOb) {
        await EditorComponent.EDITOR_COMPONENT.documentService.updateDeltaContent(delta);
    }

    onDocumentSaved(autoSaveOb) {
        const el = document.getElementById("forceSaveButton");
        if (el) {
            el.style.color = "green";
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.dataService.tableOfContents.set(null);
    }

    async ngOnInit() {
        this.subscriptions.push(this.documentService.tableOfContentsObs.subscribe(async obs => {
            this.updateSectionLabels();
        }));
        this.subscriptions.push(this.route.params.subscribe(async params => {
            //EditorComponent.LOG.debug(params);
            await this.onLoad(params);
        }));
    }

    async onLoad(params = this.route.snapshot.params) {
        this.uuid = params['project'];
        //Need this to return to the previous url
        //EditorComponent.LOG.debug('DEBUG: Initial: ', params['retUrl']);
        //EditorComponent.LOG.debug('DEBUG: decodeURI: ', decodeURI(params['retUrl']));
        //EditorComponent.LOG.debug('DEBUG: b64 decode: ', atob(decodeURI(params['retUrl'])));
        this.dataService.redirectUrl = atob(decodeURI(params['retUrl']));

        //DataService can translate writer projects and betareader projects, etc. Then get the core project
        //const prj: Project = await this.documentService.getProjectForUUID(this.dataService.getProjectForUUID(this.uuid).uuid);
        const prj: Project = this.dataService.getProjectForUUID(this.uuid);

        this.tempDeltaContent = await this.documentService.setSelectedProjectAndLoadContent(prj);

        EditorComponent.LOG.debug('DEBUG - ngOnInit: prj ', prj);
        //EditorComponent.LOG.debug('DEBUG: uuid ', this.uuid);
        EditorComponent.roleProject = this.dataService.getRoleBasedProjectForUUID(this.uuid);
        EditorComponent.LOG.debug('DEBUG: roleProject ', EditorComponent.roleProject);

        EditorComponent.platformUser = this.dataService.user();

        switch (EditorComponent.roleProject.role) {
            case PlatformRole.WRITER:
                this.isReadOnly = false;
                EditorComponent.LOG.debug('DEBUG: BEFORE SET RW ', this.modules.toolbar);
                this.toolbarContainer = this.rwToolbarContainer;
                EditorComponent.LOG.debug('DEBUG: SET RW ', this.modules.toolbar);
                break;
            case PlatformRole.BETAREADER:
                this.isReadOnly = true;
                this.toolbarContainer = this.roToolbarContainer;
                break;
            default:
                this.isReadOnly = true;
                this.toolbarContainer = this.roToolbarContainer;
                this.errorService.handleError({
                    ancillaries: [EditorComponent.roleProject.role],
                    detail: "Unable to match Role to select ReadOnly: " + EditorComponent.roleProject.role,
                    severity: "SEVERE",
                    stack: undefined,
                    summary: "Unable to match Role to select ReadOnly"
                });
        }
        this.modules.toolbar.container = this.toolbarContainer;
        EditorComponent.readyToInitializeQuill.set(true);

        if (!this.tempDeltaContent) {
            this.dataService.switchToBaseLayout();
        }
    }

    async closeNewReply() {
        this.showComments.set(false);
        //Update other replies
        if (!EditorComponent.selectedComment().replies) {
            EditorComponent.selectedComment().replies = [];
        }
        await this.referenceToCommentModule.updateCommentReplies(EditorComponent.selectedComment());
    }

    async saveNewReply() {
        this.showComments.set(false);
        EditorComponent.selectedComment().replies.push(this.newReply);
        await this.closeNewReply();
    }

    setReferenceToAutosaveModule(reference: RNGQLAutosave) {
        this.referenceToAutosaveModule = reference;
    }

    setReferenceToCommentModule(reference: RNGComments) {
        this.referenceToCommentModule = reference;
    }

    setReferenceToSectionModule(reference: RNGSectionModule) {
        this.referenceToSectionModule = reference;
    }

    markViewed() {
        Comment.markViewed(this.selectedComment(), this.dataService.user());
        if (this.selectedComment().replies?.length > 0) {
            Reply.markViewed(this.selectedComment().replies[this.selectedComment().replies.length - 1], this.dataService.user());
        }
    }

    getTableOfContents(): TableOfContents {
        return DocumentService.tableOfContents;
    }

    getDocumentMetadata(): DocumentMetadata {
        return DocumentService.documentMetadata;
    }

    getProjectSchema(): ProjectSchema {
        return this.getDocumentMetadata()?.projectSchema;
    }

    static async jumpToSection(section: Section) {
        //First, is this the TOC section? If so, jump to the start
        if (section.uuid === DocumentService.tableOfContents.topLevelSectionLink.section.uuid) {
            this.quill.setSelection(1, 0);
        } else {
            //Needs to jump to Blot, not Blot data (section in this case)
            this.quill.setSelection(this.quill.getIndex(SectionBlot.getBlotForSection(section)));
        }
        this.scrollCaretIntoView();

    }

    static scrollCaretIntoView() {

    }

    // private getCurrentSection(): Section {
    //     const caret: number = EditorComponent.quill.getSelection(true).index;
    //
    //     //Get all section tags from the DOM
    //     const sections = document.getElementsByClassName(SectionBlot.className);
    //     let selectedSection: SectionBlot = null;
    //     //Find blots for each until you find one that is after the current caret
    //     for (let i = 0; i < sections.length; i++) {
    //         const section = sections[i];
    //         const blot: SectionBlot = <SectionBlot> Quill.find(section);
    //         const idx: number = EditorComponent.quill.getIndex(blot);
    //         EditorComponent.LOG.debug("IDX and Caret:", idx, caret);
    //         if (idx > caret) {
    //             //The previous blot is section we are in
    //             return selectedSection.value().section;
    //         }
    //         selectedSection = blot;
    //     }
    //     if (!selectedSection) {
    //         this.errorService.handleError({
    //             ancillaries: [caret, sections],
    //             detail: "Unable to find any sections - document is damaged. Please reload to attempt repairs.",
    //             severity: "SEVERE",
    //             stack: undefined,
    //             summary: "Unable to find any sections - document is damaged. Please reload to attempt repairs."
    //         });
    //         return null;
    //     } else {
    //         //After the last section
    //         return selectedSection.value().section;
    //     }
    // }
    private updateSectionLabels() {
        let elements = document.getElementsByClassName(SectionBlot.className);
        for (let i = 0; i < elements.length; i++) {
            let section: Section = SectionBlot.value(elements[i]);
            elements[i].innerHTML = SectionBlot.getInnerHTML(section);
        }
        //TODO: Collapsibles: let startElement = document.getElementById('start');
        // let endElement = document.getElementById('end');
        // let elementsBetween = [];
        // let currentElement = startElement.nextElementSibling;
        //
        // while (currentElement && currentElement !== endElement) {
        //     elementsBetween.push(currentElement);
        //     currentElement = currentElement.nextElementSibling;
        // }
        // Within the parent editor wrapper:
        // For each section: Find the next section >= section level or the end of the dom
        //   Wrap everything between them in a div with a toggleable class named for the section uuid
        //   Repeat with the next section.

        //Section data should store open or closed for each section. Per user. . .

    }

    protected readyToInitializeQuill() {
        return EditorComponent.readyToInitializeQuill();
    }
}

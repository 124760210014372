<p-confirmDialog header="Confirmation" key="confirmDelete" icon="pi pi-exclamation-triangle"
                 [style]="{width: '350px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<div class="card">
    <div class="flex justify-content-between align-items-center ">
        <div class="grid col-12 pt-0 mb-2">
            <div class="col pb-0">
                <h5>{{!project || !project.id ? 'Create' : 'Edit'}} Project Profile</h5>
            </div>
            <div class="col-fixed pr-0 pt-0 relative top-0 left-0">
                <img src="../../../../assets/badges/writer.png" class="absolute right-0 top-0"/>
            </div>
        </div>
    </div>
    <div>
        <div class="card grid grid-nogutter pt-0 mt-0">
            <div class="pt-5 w-full">
                <span class="p-float-label">
                    <input [pTooltip]="" tooltipPosition="top"
                       class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                       type="text" id="title" pInputText [(ngModel)]="project.title">
                    <label for="title">Title</label>
                </span>
            </div>
            <div class="pt-5 w-full align-items-center">
                <label for="seekingBeta">Search for Beta Readers </label>
                <p-inputSwitch id="seekingBeta" [(ngModel)]="project.lookingForBetaReaders"></p-inputSwitch>
            </div>
            <div class="pt-5 pb-2 w-full">
                <span>
                    <label for="summary"> Summary <i class="pi pi-question-circle" pTooltip="Tooltip" style="font-size: 1rem"></i></label>
                    <textarea rows="6"
                          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                          type="text" inputId="summary" id="summary" pInputTextArea [(ngModel)]="project.summary"></textarea>
                </span>
            </div>
            <project-descriptor-panel [namespace]="namespace" [use]="ProjectDescriptorUse.WRITER_PROJECT"></project-descriptor-panel>
            <p-button class="col-4 col-offset-4 pt-3" [label]="!project.id ? 'Create Project' : 'Save Project'" icon="pi pi-save" (onClick)="save()" ></p-button>
        </div>
    </div>
</div>

import Quill from "quill";
import {EmbedBlot, Root} from "parchment";
import {v4 as uuid} from "uuid";
import {DocumentService} from "../../service/document.service";
import {Project} from "../../service/db/entities/Project";
import {ProjectSchemas} from "../../api/project_schemas";

// Notes: Private notes - ROLE BASED - in comments
// Open and close sections - FULL SECTION and/or metadata
// Sidebar menu format -> multiple panels of standard theme resource display
//   Unread comments listed under the sections they are "in" - click to jump
// NOTES -> rather than being metatdata, it is a section type - lowest level so available in al places (Schema parts needs new attribute - EXPORTED: boolean default true.  Notes would be false - icon pi-lightbulb

// Metadata - all but notes get synopsis metadata, and a title field that pulls to name and level


export class SectionBlot extends EmbedBlot {

    static override className = 'rng-section';
    static override blotName = 'section';
    static override tagName = 'span';

    constructor(scroll: Root, domNode) {
        super(scroll, domNode);
    }

    static buildSection(value: string | Section, node) {
        const section: Section = (typeof value === "string")? JSON.parse(value) : value;
        console.log("buildSection -- ", section);
        console.log(section);

        node.setAttribute('section-data', JSON.stringify(section));
        node.setAttribute('section-level', section.level);
        //Use this to locate a tag in the DOM
        node.id = 'RNGSECTION-'+section.uuid;
        node.classList.add(SectionBlot.className);
        node.classList.add('section-'+section.uuid);
        node.contentEditable = false;
        //node.classList.add('no-reveal-codes');

        node.innerHTML = SectionBlot.getInnerHTML(section);
    }

    static override create(value) {
        let node = super.create(value);

        SectionBlot.buildSection(value, node);
        return node;
    }

    static override value(node) {
        /*if (!node) {
            return null;
        }*/
        console.log('NODE: ', node);
        console.log('NODE DATA: ', node.getAttribute('section-data'));
        return JSON.parse(node.getAttribute('section-data'));
    }

    static getBlotForSection(section: Section): SectionBlot {
        if (section) {
            return <SectionBlot>Quill.find(document.getElementById('RNGSECTION-' + section.uuid), true);
        }
        return null;
    }

    static getInnerHTML(section: Section) {
        return "<hr/><span style='font-size:large;font-weight:bold;align-content:center'>"+DocumentService.documentMetadata.projectSchema.sectionLayout[section.level].tocIconHTML+" "+SectionBlot.getSectionLabel(section)+"</span><hr/>";
    }

    public static getSectionLabel(section: Section) {
        return DocumentService.getSectionString(section)+( (section.name != null) ? ': '+section.name : '');
    }

    //Prototype javascript function to wrap in a div -
    // needs modified to only wrap if not already wrapped, so it based on the section blot, etc.
     wrapElementsBetween(startElem, endElem) {
        const wrapper = document.createElement('div');
        let currentElem = startElem.nextElementSibling;

        while (currentElem && currentElem !== endElem) {
            wrapper.appendChild(currentElem);
            currentElem = currentElem.nextElementSibling;
        }

        startElem.parentNode.insertBefore(wrapper, endElem);
    }

}

export class Section {
    uuid: string;
    level: number;
    name: string | null;
    metadata: {key: string, value: string}[];
    caretIndex?: number;

    static createInitialSection(project: Project): Section {
        return {
            uuid: uuid(),
            level: ProjectSchemas.DEFAULT_PROJECT_SCHEMA.projectLevel,
            name: project.title,
            metadata: []
        };
    }
}
